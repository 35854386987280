<template>
  <v-app-bar color="background" fixed elevation="1">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-top-transition"
      content-class="bg-background"
      class="bg-background"
    >
      <template v-slot:activator="{ props }">
        <v-app-bar-nav-icon color="grey-lighten-1" v-bind="props"> </v-app-bar-nav-icon>
      </template>
      <v-card class="bg-background">
        <v-toolbar flat class="bg-background">
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="dialog = false">
              <v-icon color="grey-lighten-1">mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!-- account card -->
        <AvatarRow
          v-if="user"
          :avatarSrc="avatarSrc"
          :emitViewAccount="emitViewAccount"
          :emitViewAllVehicles="emitViewAllVehicles"
          :emitViewVehicle="emitViewVehicle"
          :emitViewDefaults="emitViewDefaults"
          :handleLogout="handleLogout"
          :user="user"
          class="mx-8"
        />
        <v-divider></v-divider>
        <!-- tabs section -->
        <v-card-text class="bg-background">
          <v-tabs vertical :model-value="tab" hide-slider background-color="background" optional>
            <v-tab class="text-none" tab-value="home" @click="emitViewHome">
              Home <v-spacer></v-spacer>
            </v-tab>
            <v-tab class="text-none" tab-value="account" @click="emitViewAccount">
              Account <v-spacer></v-spacer>
            </v-tab>
            <v-tab class="text-none" tab-value="vehicle-details" @click="emitViewVehicle">
              Vehicle details <v-spacer></v-spacer>
            </v-tab>
            <v-tab class="text-none" tab-value="fleet-vehicles" @click="emitViewAllVehicles">
              Fleet vehicles <v-spacer></v-spacer>
            </v-tab>
            <v-tab
              class="text-none bg-background"
              tab-value="locations-manager"
              @click="emitViewLocations"
            >
              Locations manager
              <v-spacer></v-spacer>
            </v-tab>
            <v-tab class="text-none bg-background" tab-value="defaults" @click="emitViewDefaults">
              Trip planning defaults
              <v-spacer></v-spacer>
            </v-tab>
            <v-tab
              class="text-none bg-background"
              tab-value="saved-trips"
              @click="emitViewSavedTrips"
            >
              Saved trips <v-spacer></v-spacer>
            </v-tab>
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-toolbar-title class="text-grey-lighten-1">
      {{ title }}
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn icon @click.stop="emitClose()">
      <v-icon color="grey-lighten-1">mdi-close</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script setup lang="ts">
import Driver from '@/classes/user_classes/driver'
import { ActionTypes, GettersTypes } from '@/store/store_types'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import AvatarRow from '../user/profile/AvatarRow.vue'
import type { tabs } from './DashboardDialog.vue'

const store = useStore()

const { tab = 'home' } = defineProps<{ tab: tabs | undefined }>()

const emit = defineEmits([
  'close',
  'logout',
  'view-home',
  'view-account',
  'view-vehicle',
  'view-vehicles',
  'view-defaults',
  'view-locations',
  'view-saved-trips',
])

const dialog = ref(false)
const expand = ref<number | undefined>(0)

const avatarSrc = computed(() => {
  const driver: Driver | undefined = store.getters[GettersTypes.currentDriver]
  return driver?.profilePicSrc
})

const user = computed(() => store.state.user)

const title = computed(() => {
  switch (tab) {
    case 'home':
      return 'Home'
    case 'account':
      return 'Account'
    case 'vehicle-details':
      return 'Vehicle details'
    case 'fleet-vehicles':
      return 'Fleet vehicles'
    case 'locations-manager':
      return 'Locations manager'
    case 'defaults':
      return 'Trip planning defaults'
    default:
      return ''
  }
})

watch(
  () => tab,
  (value: tabs) => {
    if (value === 'vehicle-details' || value === 'fleet-vehicles') {
      expand.value = 0
    }
  },
)

function emitClose() {
  emit('close')
  dialog.value = false
}

function handleLogout() {
  emit('logout')
  store.dispatch(ActionTypes.logoutUser)
}

function emitViewHome() {
  emit('view-home')
  dialog.value = false
}

function emitViewAccount() {
  emit('view-account')
  dialog.value = false
}

function emitViewVehicle() {
  emit('view-vehicle')
  dialog.value = false
}

function emitViewAllVehicles() {
  emit('view-vehicles')
  dialog.value = false
}

function emitViewDefaults() {
  emit('view-defaults')
  dialog.value = false
}

function emitViewLocations() {
  emit('view-locations')
  dialog.value = false
}

function emitViewSavedTrips() {
  emit('view-saved-trips')
  dialog.value = false
}
</script>
