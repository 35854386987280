import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import directus from '@/api/clients/directus-client'
import type { DirectusUploadFileRes } from '@/types/directus-types'
import { deleteFile, uploadFiles } from '@directus/sdk'

export async function uploadFile(file: File, isImage = false): Promise<DirectusUploadFileRes> {
  try {
    let resizedImg: Blob | undefined = undefined
    if (isImage) {
      resizedImg = await resizeImg(file)
    }
    const form_data = new FormData()
    const blob = resizedImg ?? new Blob([file])
    form_data.append('file', blob)
    const fileCreateRes = await directus.request(uploadFiles(form_data))
    return {
      success: true,
      UUID: fileCreateRes?.id,
    }
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
    return {
      success: false,
    }
  }
}

// Helper function to support image uploads.
async function resizeImg(file: File): Promise<Blob | undefined> {
  return new Promise((resolve, reject) => {
    const blobCallback = async (blob: Blob | null) => {
      if (blob) {
        resolve(blob)
      } else {
        reject(undefined)
      }
    }

    const MAX_WIDTH = 1920
    const MAX_HEIGHT = 1080
    const MIME_TYPE = file.type ?? 'image/jpeg'
    const QUALITY = 0.7

    const blobURL = URL.createObjectURL(file)
    const img = new Image()
    img.src = blobURL
    img.onerror = function (error) {
      URL.revokeObjectURL(img.src)
      if (import.meta.env.DEV)
        console.log('#ERROR DEV NOTIFICATION: `resizeImg` errored with an unspecified error')
      notifyAnalytics({
        type: 'log_error',
        data: error,
      })
    }
    img.onload = function () {
      URL.revokeObjectURL(img.src)
      const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT)
      const canvas = document.createElement('canvas')
      canvas.width = newWidth
      canvas.height = newHeight
      const ctx = canvas.getContext('2d')
      ctx?.drawImage(img, 0, 0, newWidth, newHeight)
      canvas.toBlob((blob) => blobCallback(blob), MIME_TYPE, QUALITY)
    }
  })
}

// Helper function to support image uploads.
function calculateSize(
  img: HTMLImageElement,
  maxWidth: number,
  maxHeight: number,
): [number, number] {
  let width = img.width
  let height = img.height

  // calculate the width and height, constraining the proportions
  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width)
      width = maxWidth
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height)
      height = maxHeight
    }
  }
  return [width, height]
}

/**
 * Requests a file be deleted on directus, note this should be a cascading delete also removing the file from S3 storage.
 *
 * @param fileUUID the UUID of the file to delete.
 * @returns if process was successful or not
 */
export async function deleteDirectusFile(fileUUID: string): Promise<{ success: boolean }> {
  try {
    await directus.request(deleteFile(fileUUID))
    return { success: true }
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
    return { success: false }
  }
}
