<template>
  <v-btn
    style="z-index: 1002; position: absolute; top: 8px; right: 8px"
    :small="smAndUp"
    :class="smAndUp ? 'rounded-pill text-none px-4' : ''"
    :color="hasFilteredNetworks ? (smAndUp ? 'pwt_azure_80' : 'white') : undefined"
    @click="openNetworkSettings"
    :icon="xs ? true : false"
  >
    <v-icon> mdi-filter-variant </v-icon>
    <span v-if="smAndUp" style="color: #161616"> Networks </span>
  </v-btn>
</template>
<script setup lang="ts">
import { MutationTypes, SecondaryDialogContent } from '@/store/store_types'
import { computed } from 'vue'
import { useDisplay } from 'vuetify'
import { useStore } from 'vuex'

const { smAndUp, xs } = useDisplay()

const store = useStore()

const hasFilteredNetworks = computed(() => !!store.state.networkSelectionOverride)

const openNetworkSettings = () => {
  store.commit(MutationTypes.setSecondaryDialogContent, SecondaryDialogContent.NETWORK_SETTINGS)
}
</script>
