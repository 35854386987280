import { Duration, type DurationLikeObject } from 'luxon'

/**
 * Returns a human readable display string for a given duration in seconds.
 * @param seconds duration in seconds
 * @returns human readable display string
 */
export function getNiceDuration(
  seconds: number,
  display: 'short' | 'long' | 'narrow' = 'narrow',
): string {
  const timeObj: DurationLikeObject = {
    minutes: Math.round(seconds / 60),
  }
  if (seconds >= 3600) {
    timeObj.hours = 0
  }

  return Duration.fromObject(timeObj)
    .normalize()
    .toHuman(
      display === 'long'
        ? { unitDisplay: 'long' }
        : display === 'short'
          ? { unitDisplay: 'short' }
          : { listStyle: 'narrow' },
    )
    .replace(',', '')
}
