<template>
  <v-btn
    color="pwt_palatinate_blue"
    variant="text"
    :disabled="disabled"
    :loading="loading"
    class="text-none rounded-pill px-5 text-decoration-underline"
    @click="emitClick"
    style="text-underline-offset: 4px !important"
  >
    <slot></slot>
  </v-btn>
</template>
<script setup lang="ts">

interface Props {
  loading?: boolean
  disabled?: boolean
}

const { loading = false, disabled = false } = defineProps<Props>()

const emit = defineEmits(['click'])

function emitClick() {
  emit('click')
}
</script>
