// magic numbers

/**
 * Mean passenger weight in kg.
 *
 * rounded to nearest kg based on mean weight adult NZ in 2023 combined for
 * genders and ethnicity category (this does not take into account the
 * density of the sample groups).
 */
export const meanPassengerWeight = 85 // TODO: find better solution that google search data.

/**
 * Max number of waypoints ev nav route planning can support.
 */
export const evNavMaxWaypoints = 2
