import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import directus, { storage } from '@/api/clients/directus-client'
import { keycloak } from '@/main'

/**
 * API Call to check if current token exists and is valid.
 * This will authenticate the directus connection if successful.
 *
 * @returns `true` if token auth successful `false` if failed.
 */
export async function checkToken(): Promise<boolean> {
  let authenticated = false
  const realm = keycloak.realm

  if (realm) {
    await fetch('https://auth.powertrip.earth/auth/realms/' + realm + '/directus_sso_sidecar', {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => response.json())
      .then(async (res: AuthRes) => {
        // set custom credentials to the storage
        storage.set(res.data)
        await directus
          .refresh()
          .then((data) => {
            authenticated = true
          })
          .catch((err: unknown) => {
            console.log(err)
            // The user is not authenticated
            keycloak.logout({redirectUri: window.location.origin,})
          })
      })
  }
  return authenticated
}

/**
 * API Call to logout the user.
 * This will invalidate the directus connection if successful and is a fire and forget style function.
 */
export async function logout() {
  try {
    await directus.logout()
    storage.clear() //remove directus tokens
    await keycloak.logout({
      redirectUri: window.location.origin,
    })
  } catch (error) {
    notifyAnalytics({ type: 'log_error', data: error })
  }
}

interface AuthRes {
  data: Data
}

interface Data {
  access_token: string
  refresh_token: string
  expires: number
}
