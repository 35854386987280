<template>
  <v-autocomplete
    no-filter
    v-model="address"
    :clearable="!readonly"
    v-model:search="query"
    :items="items"
    item-title="address"
    return-object
    @update:model-value="flagForUpdate"
    :label="label"
    :dark="dark"
    attach
    :placeholder="placeholder"
    persistent-placeholder
    :error-messages="errorMsg"
    :loading="loading || fetching"
    :hide-no-data="query === null || query === '' || query?.length === 1"
    :no-data-text="fetching ? 'Loading' : 'Nothing matches your search criteria'"
    :readonly="readonly"
    @click:clear="() => emit('clear')"
    @click="() => emit('click')"
    bg-color="transparent"
  >
    <template v-slot:append v-if="(!readonly && appendIcon) || (!readonly && address)">
      <v-icon
        @click="() => emit('append')"
        size="small"
        color="grey"
        :icon="appendIcon ? appendIcon : 'mdi-pencil'"
      />
    </template>
    <template v-slot:item="{ props }">
      <v-list-item v-bind="props" :key="generateUuid()"></v-list-item>
    </template>
  </v-autocomplete>
</template>
<script lang="ts" setup>
import { fetchLocationAutoCompleteDetails } from '@/api/calls/maps-calls/address-autocomplete-calls'
import Coordinate from '@/classes/common_classes/coordinate'
import { MutationTypes, type State } from '@/store/store_types'
import processAddressSearchResults, {
  type AutoCompleteReturnObject,
  type processedAddressObj,
} from '@/utils/processAddressSearchResults'
import debounce from 'lodash.debounce'
import { v4 as uuid } from 'uuid'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'

export interface AddressAutocompleteInputUpdateObj {
  id: string
  addressData: processedAddressObj | undefined
}

const emit = defineEmits({
  clear: null,
  click: null,
  append: null,
  update: (updateObj: AddressAutocompleteInputUpdateObj) => !!updateObj,
})

interface Props {
  id?: string
  initialValue?: processedAddressObj
  label?: string
  dark?: boolean
  errorMsg?: string | null
  loading?: boolean
  geoLocation?: Coordinate
  allowFavLocations?: boolean
  placeholder?: string
  readonly?: boolean
  appendIcon?: string
}

const {
  id = '',
  initialValue = undefined,
  label = '',
  dark = false,
  errorMsg = null,
  loading = false,
  geoLocation = undefined,
  allowFavLocations = false,
  placeholder = 'Enter an address',
  readonly = false,
  appendIcon = undefined,
} = defineProps<Props>()

const store = useStore<State>()

const userGeoLocation = computed(() => store.state.userGeoIPData)
const pannedCenter = computed(() => store.state.pannedCenter)
const favLocations = computed(() => store.state.favLocations)

const address = ref<processedAddressObj | undefined>(undefined)
const items = ref<processedAddressObj[]>([])
const query = ref<undefined | string>(undefined)
const results = ref<AutoCompleteReturnObject[] | null>(null)
const initialLoad = ref(true)
const fetching = ref(false)

function generateUuid() {
  return uuid()
}

watch(
  query,
  debounce(async (val) => {
    if (initialLoad.value && initialValue) {
      initialLoad.value = false
      return
    }
    if (initialLoad.value) initialLoad.value = false
    if (val) {
      fetching.value = true
      fetchLocationAutoCompleteDetails(
        val,
        getBiasLocation(),
        geoLocation || pannedCenter ? 0.5 : undefined,
      ).then((data) => {
        results.value = data
        // assumes results watch will clear fetching flag once complete.
      })
      .catch((err) => {
        console.error(err)
        fetching.value = false
      })
    }
  }, 300),
)

watch(results, () => {
  // convert results to items
  if (results.value && query.value) {
    items.value = []
    const processedRes = processAddressSearchResults(results.value)
    const processedFav = favLocations.value.map((fav) => fav.toProcessedAddressObj)
    const filteredFav = query.value
      ? processedFav.filter((fav) =>
          fav.address
            .toLowerCase()
            .replace(',', '')
            .includes((query.value || '').toLowerCase().replace(',', '')),
        )
      : []
    items.value = allowFavLocations ? [...filteredFav, ...processedRes] : processedRes
  }
  // clear fetching flag
  fetching.value = false
})

watch(
  () => initialValue,
  (val) => {
    if (!initialLoad.value) {
      if (val && val.address != '') {
        items.value = [val]
      }
      address.value = val
    }
  },
)

function flagForUpdate() {
  if (address.value && address.value.address) {
    store.commit(MutationTypes.addToAddressSearchHistory, address.value) // add to search history
  }
  emit('update', {
    id: id,
    addressData: address.value,
  })
}

function setInitialValues() {
  if (initialValue) {
    address.value = initialValue
    if (address.value.address != '') {
      items.value = [initialValue]
    }
  }
  nextTick(() => {
    initialLoad.value = false
  })
}

onMounted(() => {
  nextTick(() => {
    setInitialValues()
  })
})

function getBiasLocation(): Coordinate | undefined {
  // prioritize passed location over user panned map display central location over programmatically sourced user location.
  if (geoLocation) return geoLocation // use passed location.
  if (pannedCenter.value)
    return new Coordinate({
      latitude: pannedCenter.value.lat,
      longitude: pannedCenter.value.lng,
    }) // use map display center.
  return userGeoLocation.value // use ip sourced location.
}
</script>
<style scoped>
:deep(
  .v-field__input input::placeholder,
  input.v-field__input::placeholder,
  textarea.v-field__input::placeholder
) {
  font-style: italic;
  color: #161616;
  opacity: 1;
}
</style>
