<template>
  <v-card elevation="3">
    <v-card-title class="mb-5">
      <v-icon color="primary" class="mr-3"> mdi-piggy-bank-outline </v-icon>
      ${{
        Math.round(
          (oneYearProjections.totalEquivalentFuelCost +
            (calcVs === 'PETROL'
              ? oneYearProjections.totalPetrolRoadUserCharges
              : oneYearProjections.totalDieselRoadUserCharges) +
            oneYearProjections.totalPetrolMaintenanceCost -
            oneYearProjections.totalPublicChargingCost -
            oneYearProjections.totalPrivateChargingCost -
            oneYearProjections.totalEVMaintenanceCost -
            oneYearProjections.totalElectricRoadUserCharges) *
          years
        ).toLocaleString()
      }}
    </v-card-title>
    <v-card-text>
      Estimated savings over 5 years
      <v-card class="bg-background mt-5" flat>
        <v-icon color="primary" class="ml-2 mr-4">custom:fireIcon</v-icon>
        <span>
          {{ Math.round(oneYearProjections.totalAvoidedEmissions * years).toLocaleString() }}
          kg CO₂ emissions avoided
        </span>
      </v-card>
      <v-card class="bg-background mt-5" flat>
        <v-icon color="primary" class="ml-2 mr-4" icon="custom:pliers" />
        <span>
          ${{
            Math.round(
              (oneYearProjections.totalPetrolMaintenanceCost -
                oneYearProjections.totalEVMaintenanceCost) *
              years
            ).toLocaleString()
          }}
          maintenance savings
        </span>
      </v-card>
      <v-card class="bg-background mt-5" flat>
        <v-icon color="primary" class="ml-2 mr-4" icon="custom:waterDrop" />
        <span>
          ${{
            Math.round(
              (oneYearProjections.totalEquivalentFuelCost +
                oneYearProjections.totalPublicChargingCost -
                oneYearProjections.totalPrivateChargingCost) *
              years
            ).toLocaleString()
          }}
          fuel savings
        </span>
      </v-card>
      <OutlinedBlockBtn class="mt-5 mb-2 rounded-pill" @click="toSavedTrips">
        See all saved trips & estimated savings
      </OutlinedBlockBtn>
    </v-card-text>
  </v-card>
</template>
<script setup lang="ts">
import OutlinedBlockBtn from '@/components/ui-elements/buttons/OutlinedBlockBtn.vue'
import { GettersTypes, MutationTypes } from '@/store/store_types'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const oneYearProjections = computed(() => store.getters[GettersTypes.getOneYearProjections])
const calcVs = computed(() => store.state.calcVs)

const years = ref(5)

function toSavedTrips() {
  store.commit(MutationTypes.setDashboardDialogContent, 'saved-trips')
  store.commit(MutationTypes.setShowDashboard, true)
}
</script>
