<template>
  <v-dialog
    :model-value="activator"
    :width="mobile ? '80%' : '70%'"
    :content-class="xs ? '' : 'rounded-lg'"
    style="z-index: 1200"
    @click:outside="emitClose()"
    @keydown="keyDown($event)"
    scrollable
    :max-width="maxWidth"
    :min-width="minWidth"
    :fullscreen="xs"
    retain-focus
    :min-height="mobile ? undefined : '80vh'"
    :max-height="mobile ? undefined : '90vh'"
  >
    <v-card
      class="pa-5 bg-background overflow-hidden"
      :class="xs ? '' : 'rounded-lg'"
      style="height: 100%; position: relative"
    >
      <div class="pwt-scroll-container pwt-scrollbar-styles overflow-y-auto overflow-x-hidden">
        <slot></slot>
      </div>
      <v-btn
        @click="emitClose"
        style="position: absolute; top: 8px; right: 12px"
        color="#F3F3F3"
        flat
        rounded="lg"
        class="px-1"
      >
        <v-icon color="grey-lighten-1">mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useDisplay } from 'vuetify';

const { mobile, xs } = useDisplay()

const emit = defineEmits(['close'])

const {
  activator,
  small = false,
  large = false,
} = defineProps<{ activator: boolean; small?: boolean; large?: boolean }>()

const maxWidth = computed(() => {
  if (small && mobile.value) return '400px'
  if (small) return '650px'
  if (large && mobile.value) return '900px'
  if (large) return '1400px'
  if (mobile.value) return '650px'
  return '900px'
})

const minWidth = computed(() => {
  if (small && mobile.value) return '250px'
  if (small) return '450px'
  if (large && mobile.value) return '600px'
  if (large) return '1000px'
  if (mobile.value) return '350px'
  return '700px'
})

const emitClose = () => {
  emit('close')
}

const keyDown = (event: KeyboardEvent) => {
  if (event.key === 'Escape') emitClose()
}
</script>
<style scoped>
.pwt-scroll-container {
  width: 100%;
  height: 100%;
}

.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}
</style>
