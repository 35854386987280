import type {
  ConnectorFormat,
  ConnectorType,
  PowerType,
} from "../types/charger_Db_types";
import getAssetSrc from "../utils/getAssetSrc";

export interface ConnectorDetailsData {
  standard: ConnectorType;
  powerType: PowerType | PowerType[];
  displayName: string;
  format: ConnectorFormat | ConnectorFormat[];
  iconURL?: string | ConnectorDetailsIconData;
}

export interface ConnectorDetailsIconData {
  cable?: string;
  socket?: string;
}

/** Map linking standard with additional data to be used in the app. */
export const connectorDetailsDataMap: Map<ConnectorType, ConnectorDetailsData> =
  new Map([
    [
      "CHADEMO",
      {
        displayName: "CHAdeMO",
        powerType: "DC",
        standard: "CHADEMO",
        format: "CABLE",
        iconURL: "plug_images/CHAdeMOTethered.svg",
      },
    ],
    [
      "CHAOJI",
      {
        displayName: "CHAOJI",
        powerType: "DC",
        standard: "CHAOJI",
        format: ["CABLE", "SOCKET"],
        iconURL: {
          cable: "plug_images/CHAOJITethered.svg",
          socket: "plug_images/CHAOJISocketed.svg",
        },
      },
    ],
    [
      "DOMESTIC_A",
      {
        displayName: "Wall plug - Type A",
        powerType: "AC_1_PHASE",
        standard: "DOMESTIC_A",
        format: "SOCKET",
        iconURL: "plug_images/WallPlugA.svg",
      },
    ],
    [
      "DOMESTIC_B",
      {
        displayName: "Wall plug - Type B",
        powerType: "AC_1_PHASE",
        standard: "DOMESTIC_B",
        format: "SOCKET",
        iconURL: "plug_images/WallPlugB.svg",
      },
    ],
    [
      "DOMESTIC_C",
      {
        displayName: "Wall plug - Type C",
        powerType: "AC_1_PHASE",
        standard: "DOMESTIC_C",
        format: "SOCKET",
        iconURL: "plug_images/WallPlugC.svg",
      },
    ],
    [
      "DOMESTIC_D",
      {
        displayName: "Wall plug - Type D",
        powerType: "AC_1_PHASE",
        standard: "DOMESTIC_D",
        format: "SOCKET",
        iconURL: "plug_images/WallPlugD.svg",
      },
    ],
    [
      "DOMESTIC_E",
      {
        displayName: "Wall plug - Type E",
        powerType: "AC_1_PHASE",
        standard: "DOMESTIC_E",
        format: "SOCKET",
        iconURL: "plug_images/WallPlugE.svg",
      },
    ],
    [
      "DOMESTIC_F",
      {
        displayName: "Wall plug - Type F",
        powerType: "AC_1_PHASE",
        standard: "DOMESTIC_F",
        format: "SOCKET",
        iconURL: "plug_images/WallPlugF.svg",
      },
    ],
    [
      "DOMESTIC_G",
      {
        displayName: "Wall plug - Type G",
        powerType: "AC_1_PHASE",
        standard: "DOMESTIC_G",
        format: "SOCKET",
        iconURL: "plug_images/WallPlugG.svg",
      },
    ],
    [
      "DOMESTIC_H",
      {
        displayName: "Wall plug - Type H",
        powerType: "AC_1_PHASE",
        standard: "DOMESTIC_H",
        format: "SOCKET",
        iconURL: "plug_images/WallPlugH.svg",
      },
    ],
    [
      "DOMESTIC_I",
      {
        displayName: "Wall plug (NZ and Australia)",
        powerType: "AC_1_PHASE",
        standard: "DOMESTIC_I",
        format: "SOCKET",
        iconURL: "plug_images/WallPlugI.svg",
      },
    ],
    [
      "DOMESTIC_J",
      {
        displayName: "Wall plug - Type J",
        powerType: "AC_1_PHASE",
        standard: "DOMESTIC_J",
        format: "SOCKET",
        iconURL: "plug_images/WallPlugJ.svg",
      },
    ],
    [
      "DOMESTIC_K",
      {
        displayName: "Wall plug - Type K",
        powerType: "AC_1_PHASE",
        standard: "DOMESTIC_K",
        format: "SOCKET",
        iconURL: "plug_images/WallPlugK.svg",
      },
    ],
    [
      "DOMESTIC_L",
      {
        displayName: "Wall plug - Type L",
        powerType: "AC_1_PHASE",
        standard: "DOMESTIC_L",
        format: "SOCKET",
        iconURL: "plug_images/WallPlugL.svg",
      },
    ],
    [
      "DOMESTIC_M",
      {
        displayName: "Wall plug - Type M",
        powerType: "AC_1_PHASE",
        standard: "DOMESTIC_M",
        format: "SOCKET",
      },
    ],
    [
      "DOMESTIC_N",
      {
        displayName: "Wall plug - Type N",
        powerType: "AC_1_PHASE",
        standard: "DOMESTIC_N",
        format: "SOCKET",
      },
    ],
    [
      "DOMESTIC_O",
      {
        displayName: "Wall plug - Type O",
        powerType: "AC_1_PHASE",
        standard: "DOMESTIC_O",
        format: "SOCKET",
      },
    ],
    [
      "GBT_AC",
      {
        displayName: "GB/T AC",
        powerType: ["AC_1_PHASE", "AC_2_PHASE", "AC_3_PHASE"],
        standard: "GBT_AC",
        format: ["CABLE", "SOCKET"],
      },
    ],
    [
      "GBT_DC",
      {
        displayName: "GB/T DC",
        powerType: "DC",
        standard: "GBT_DC",
        format: "CABLE",
      },
    ],
    [
      "IEC_60309_2_single_16",
      {
        displayName: "Blue Commando",
        powerType: "AC_1_PHASE",
        standard: "IEC_60309_2_single_16",
        format: "SOCKET",
        iconURL: "plug_images/BlueCommandoSocket.svg",
      },
    ],
    [
      "IEC_60309_2_three_16",
      {
        displayName: "Red Commando",
        powerType: "AC_3_PHASE",
        standard: "IEC_60309_2_three_16",
        format: "SOCKET",
      },
    ],
    [
      "IEC_60309_2_three_32",
      {
        displayName: "Red Commando",
        powerType: "AC_3_PHASE",
        standard: "IEC_60309_2_three_32",
        format: "SOCKET",
      },
    ],
    [
      "IEC_60309_2_three_64",
      {
        displayName: "Red Commando",
        powerType: "AC_3_PHASE",
        standard: "IEC_60309_2_three_64",
        format: "SOCKET",
      },
    ],
    [
      "IEC_62196_T1",
      {
        displayName: "Type 1",
        powerType: ["AC_1_PHASE", "AC_2_PHASE", "AC_3_PHASE"],
        standard: "IEC_62196_T1",
        format: ["CABLE", "SOCKET"],
        iconURL: {
          cable: "plug_images/Type1Tethered.svg",
          socket: "plug_images/Type1Socketed.svg",
        },
      },
    ],
    [
      "IEC_62196_T1_COMBO",
      {
        displayName: "Type 1 CSS",
        powerType: "DC",
        standard: "IEC_62196_T1_COMBO",
        format: "CABLE",
        iconURL: "plug_images/Type1CCSTethered.svg",
      },
    ],
    [
      "IEC_62196_T2",
      {
        displayName: "Type 2",
        powerType: ["AC_1_PHASE", "AC_2_PHASE", "AC_3_PHASE"],
        standard: "IEC_62196_T2",
        format: ["CABLE", "SOCKET"],
        iconURL: {
          cable: "plug_images/Type2Tethered.svg",
          socket: "plug_images/Type2Socketed.svg",
        },
      },
    ],
    [
      "IEC_62196_T2_COMBO",
      {
        displayName: "Type 2 CSS",
        powerType: "DC",
        standard: "IEC_62196_T2_COMBO",
        format: "CABLE",
        iconURL: "plug_images/Type2CCSTethered.svg",
      },
    ],
    [
      "IEC_62196_T3A",
      {
        displayName: "Type 3",
        powerType: ["AC_1_PHASE", "AC_2_PHASE", "AC_3_PHASE"],
        standard: "IEC_62196_T3A",
        format: ["CABLE", "SOCKET"],
      },
    ],
    [
      "IEC_62196_T3C",
      {
        displayName: "SCAME",
        powerType: "DC",
        standard: "IEC_62196_T3C",
        format: ["CABLE", "SOCKET"],
      },
    ],
    [
      "NEMA_5_20",
      {
        displayName: "NEMA_5_20",
        powerType: "AC_1_PHASE",
        standard: "NEMA_5_20",
        format: "SOCKET",
      },
    ],
    [
      "NEMA_6_30",
      {
        displayName: "NEMA_6_30",
        powerType: "AC_1_PHASE",
        standard: "NEMA_6_30",
        format: "SOCKET",
      },
    ],
    [
      "NEMA_6_50",
      {
        displayName: "NEMA_6_50",
        powerType: "AC_1_PHASE",
        standard: "NEMA_6_50",
        format: "SOCKET",
      },
    ],
    [
      "NEMA_10_30",
      {
        displayName: "NEMA_10_30",
        powerType: "AC_1_PHASE",
        standard: "NEMA_10_30",
        format: "SOCKET",
      },
    ],
    [
      "NEMA_10_50",
      {
        displayName: "NEMA_10_50",
        powerType: "AC_1_PHASE",
        standard: "NEMA_10_50",
        format: "SOCKET",
      },
    ],
    [
      "NEMA_14_30",
      {
        displayName: "NEMA_14_30",
        powerType: "AC_1_PHASE",
        standard: "NEMA_14_30",
        format: "SOCKET",
      },
    ],
    [
      "NEMA_14_50",
      {
        displayName: "NEMA_14_50",
        powerType: "AC_1_PHASE",
        standard: "NEMA_14_50",
        format: "SOCKET",
      },
    ],
    [
      "PANTOGRAPH_BOTTOM_UP",
      {
        displayName: "Pantograph bottom-up",
        powerType: "DC",
        standard: "PANTOGRAPH_BOTTOM_UP",
        format: "CABLE",
      },
    ],
    [
      "PANTOGRAPH_TOP_DOWN",
      {
        displayName: "Pantograph top-down",
        powerType: "DC",
        standard: "PANTOGRAPH_TOP_DOWN",
        format: "CABLE",
      },
    ],
    [
      "TESLA_R",
      {
        displayName: "Tesla Roadster Charger",
        powerType: "AC_1_PHASE",
        standard: "TESLA_R",
        format: "CABLE",
      },
    ],
    [
      "TESLA_S",
      {
        displayName: "Tesla Supercharger",
        powerType: ["DC", "AC_1_PHASE"],
        standard: "TESLA_S",
        format: "CABLE",
        iconURL: "plug_images/TeslaTethered.svg",
      },
    ],
    [
      "HYDROGEN",
      {
        displayName: "Hydrogen",
        powerType: "DC",
        standard: "HYDROGEN",
        format: "CABLE",
        iconURL: "plug_images/Hydrogen.svg",
      }
    ]
  ]);

/**
 * Returns an array of all possible supported tethered connectors.
 *
 * @returns array of `ConnectorDetailsData` objects.
 */
export function getAllTetheredConnectors(): ConnectorDetailsData[] {
  // create collection array to populate.
  const tempArray: ConnectorDetailsData[] = [];
  // populate array.
  connectorDetailsDataMap.forEach((item) => {
    if (Array.isArray(item.format) && item.format.includes("CABLE")) {
      tempArray.push(item);
    } else if (item.format === "CABLE") {
      tempArray.push(item);
    }
  });
  // return populated collection array.
  return tempArray;
}

/**
 * Returns an array of all possible supported socketed connectors.
 *
 * @returns array of `ConnectorDetailsData` objects.
 */
export function getAllSocketedConnectors(): ConnectorDetailsData[] {
  // create collection array to populate.
  const tempArray: ConnectorDetailsData[] = [];
  // populate array.
  connectorDetailsDataMap.forEach((item) => {
    if (Array.isArray(item.format) && item.format.includes("SOCKET")) {
      tempArray.push(item);
    } else if (item.format === "SOCKET") {
      tempArray.push(item);
    }
  });
  // return populated collection array.
  return tempArray;
}

export function getConnectorAssetSrc(
  standard: ConnectorType,
  format: ConnectorFormat
): string | undefined {
  const data = connectorDetailsDataMap.get(standard);
  let src: string | undefined = undefined;
  if (data) {
    if (data.iconURL && typeof data.iconURL === "string") src = data.iconURL;
    if (data.iconURL && typeof data.iconURL === "object") {
      if (format === "CABLE") src = data.iconURL.cable;
      if (format === "SOCKET") src = data.iconURL.socket;
    }
  }
  if (src) return getAssetSrc(src);
  return undefined;
}
