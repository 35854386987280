import evNavDefaultData from '@/data/eVNavDefaultData'
import type { CDB_EVModel } from '@/types/charger_Db_types'
import type { EVNavPlug } from '@/types/ev_nav_types'
import getDirectusImgUrl from '@/utils/getDirectusImgUrl'
import to2DP from '@/utils/to2DP'

export default class EVModel {
  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  /** The model ID. */
  id: string

  /** The make/model display name. */
  name: string

  /** The make name. */
  make: string

  /** The model name. */
  model: string

  /** The approx weight of the vehicle in kgs. */
  mass: number

  /**
   * The aerodynamic drag coefficient is a measure of the effectiveness of a streamline
   * aerodynamic body shape in reducing the air resistance to the forward motion of a
   * vehicle.
   * */
  dragCoefficient: number

  /** The amount of energy recovered during regenerative braking. */
  regenRecovery: number

  /** The approx % of energy transferred from the battery to moving the vehicle.
   *
   * NOTE: The rest is lost during the process. */
  powerChainEfficiency: number

  /** The year this EV was produced. */
  year: number

  /** The max kWH a brand new battery for this model can take. */
  batterySize: number

  /** expected litres of fuel consumed per km travelled. */
  equivalentFuelEfficiency: number

  /** the models fuel type */
  fuelType: 'PETROL' | 'ELECTRIC' | 'DIESEL' | 'HYDROGEN'

  /** The list of compatible plugs for this model. */
  compatibleConnectors: EVNavPlug[]

  /** The UUID for the generic image for this model. */
  imageUUID?: string

  /** max AC current that the model can receive in kW. */
  maxElectricPowerAC: number

  /** max DC current that the model can receive in kW. */
  maxElectricPowerDC: number

  /** The Valhalla costing mode associated with this model. */
  costingType?: 'auto' | 'truck' | 'motorcycle' | 'bicycle'

  /** The charger database directus UUID. */
  modelDBImageUUID?: string

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    id,
    name,
    make,
    model,
    mass,
    dragCoefficient,
    regenRecovery,
    powerChainEfficiency,
    year,
    batterySize,
    equivalentFuelEfficiency,
    fuelType,
    compatibleConnectors = [],
    maxElectricPowerAC,
    maxElectricPowerDC,
    costingType = undefined,
    modelDBImageUUID = undefined,
  }: {
    id: string
    name: string
    make: string
    model: string
    mass: number
    dragCoefficient: number
    regenRecovery: number
    powerChainEfficiency: number
    year: number
    batterySize: number
    equivalentFuelEfficiency: number
    fuelType: 'PETROL' | 'ELECTRIC' | 'DIESEL' | 'HYDROGEN'
    compatibleConnectors?: EVNavPlug[]
    maxElectricPowerAC: number
    maxElectricPowerDC: number
    costingType?: 'auto' | 'truck' | 'motorcycle' | 'bicycle'
    modelDBImageUUID?: string
  }) {
    this.batterySize = batterySize
    this.dragCoefficient = dragCoefficient
    this.equivalentFuelEfficiency = equivalentFuelEfficiency
    this.fuelType = fuelType
    this.id = id
    this.mass = mass
    this.name = name
    this.make = make
    this.model = model
    this.compatibleConnectors = compatibleConnectors
    this.powerChainEfficiency = powerChainEfficiency
    this.regenRecovery = regenRecovery
    this.year = year
    this.maxElectricPowerAC = maxElectricPowerAC
    this.maxElectricPowerDC = maxElectricPowerDC
    this.costingType = costingType
    this.modelDBImageUUID = modelDBImageUUID
  }

  /**
   * Creates a new `EVModel` object from the expected data received form the EV Nav api.
   *
   * @param data the whole data object for the `EVNavModel` returned by the EV Nav api.
   * @returns new `EVModel` class object.
   */
  static fromChargerDbData(data: CDB_EVModel) {
    return new EVModel({
      batterySize: data.max_restricted_battery_size,
      dragCoefficient: data.drag_coefficient,
      equivalentFuelEfficiency: data.equivalent_fuel_efficiency,
      fuelType: data.fuel_type,
      id: data.id,
      mass: data.mass,
      name: data.name,
      make: data.make,
      model: data.model,
      compatibleConnectors: data.compatible_connectors ?? undefined,
      powerChainEfficiency: data.power_chain_efficiency,
      regenRecovery: data.regenerative_breaking_efficiency,
      year: data.year,
      maxElectricPowerAC: data.max_electric_power_ac ?? evNavDefaultData.MaxElectricPowerAc,
      maxElectricPowerDC: data.max_electric_power_dc ?? evNavDefaultData.MaxElectricPowerDc,
      costingType: data.valhalla_costing_mode ?? undefined,
      modelDBImageUUID: data.image ?? undefined,
    })
  }

  // -------------------------------------------------------------------- //S
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /** Returns the full src path to the default image for this model if there is one. */
  public get imageSrc(): string | undefined {
    if (this.imageUUID) return getDirectusImgUrl(this.imageUUID)
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Methods ----------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Calculates the remaining usable battery in kWH after reducing its state of health through linear degradation.
   *
   * @returns the expected battery size usable in kWh.
   */
  public calcLinearDegradation() {
    return Math.round(this.batterySize * (1 - (new Date().getFullYear() - this.year) * 0.03))
  }

  /**
   * Calculates the ev models state of health through linear degradation.
   *
   * @returns the expected state of health as a decimal representation of a percentage e.g. 80% = 0.8.
   */
  public calcLinearDegradationSOH() {
    return to2DP(1 - (new Date().getFullYear() - this.year) * 0.03)
  }
}
