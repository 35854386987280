<template>
  <v-btn
    block
    :color="color"
    :disabled="disabled"
    :loading="loading"
    class="rounded-lg text-none"
    :class="darkText ? 'text-black' : 'text-white'"
    @click="emitClick"
    variant="elevated"
  >
    <slot></slot>
  </v-btn>
</template>
<script setup lang="ts">

interface Props {
  loading?: boolean
  disabled?: boolean
  color?: string
  darkText?: boolean
}

const {
  loading = false,
  disabled = false,
  color = 'pwt_palatinate_blue',
  darkText = false,
} = defineProps<Props>()

const emit = defineEmits(['click'])

function emitClick() {
  emit('click')
}
</script>
