<template>
  <v-card flat class="pb-5 px-5">
    <v-card-text>
      <v-form :disabled="!!processingStatus" v-model="valid" validate-on="input">
        <v-text-field
          v-model="vehicleName"
          label="Name your vehicle (optional)"
          autofocus
          clearable
        ></v-text-field>
        <v-text-field v-model="plate" label="License Plate (optional)" clearable></v-text-field>
        <v-text-field v-model="VIN" label="VIN (optional)" clearable></v-text-field>
        <v-tabs
          grow
          color="white"
          class="pwt-tabs-border"
          slider-color="primary"
          v-model="tab"
          background-color="background"
        >
          <v-tab class="rounded-t-lg text-none">
            <span :class="tab === 0 ? 'pwt-custom-active-tab-text' : ''"> Basic settings </span>
          </v-tab>
          <v-tab class="rounded-t-lg text-none">
            <span :class="tab === 1 ? 'pwt-custom-active-tab-text' : ''"> Advanced settings </span>
          </v-tab>
        </v-tabs>
        <v-tabs-window v-model="tab">
          <v-tabs-window-item :ripple="false" class="pt-5">
            <v-select
              label="Fuel type (required)"
              :items="['Petrol', 'Diesel', 'Hybrid', 'Electric', 'Hydrogen']"
              v-model="fuelType"
              :rules="[validateFuelType]"
            ></v-select>
            <v-autocomplete
              v-if="fuelType === 'Electric' || fuelType === 'Hydrogen'"
              v-model="evModel"
              :items="EVModels"
              item-title="name"
              label="EV make/model (optional)"
              :menu-props="{ contentClass: 'pwt-scrollbar-styles' }"
              return-object
              clearable
              @update:modelValue="handleModelSelect"
            ></v-autocomplete>
            <v-text-field
              v-if="fuelType === 'Electric'"
              v-model="batterySize"
              label="Battery size (required)"
              type="number"
              clearable
              suffix="kWh"
            ></v-text-field>
            <v-slider
              v-if="fuelType === 'Electric'"
              :label="'Range adjustment ' + SOH + '%'"
              track-color="grey-lighten-2"
              v-model="SOH"
              min="1"
              max="100"
              class="pr-2 pb-3"
              :disabled="!!processingStatus"
              :rules="[validateBattery]"
              :messages="batteryAgeMessage"
              step="1"
              color="primary"
              thumb-size="16"
              track-size="6"
            ></v-slider>
            <v-autocomplete
              label="Connectors (required)"
              v-if="fuelType === 'Electric' || fuelType === 'Hydrogen'"
              chips
              clearable
              multiple
              :menu-props="{ contentClass: 'pwt-scrollbar-styles' }"
              return-object
              v-model="selectedConnectors"
              :items="connectors"
              item-title="displayName"
              :rules="[validateSelectedConnectors]"
              :disabled="fuelType === 'Hydrogen'"
            ></v-autocomplete>
          </v-tabs-window-item>
          <v-tabs-window-item :ripple="false" class="pt-5">
            <v-select
              label="Fuel type (required)"
              :items="['Petrol', 'Diesel', 'Hybrid', 'Electric', 'Hydrogen']"
              v-model="fuelType"
              :rules="[validateFuelType]"
            ></v-select>
            <v-autocomplete
              v-if="fuelType === 'Electric'  || fuelType === 'Hydrogen'"
              v-model="evModel"
              :items="EVModels"
              item-title="name"
              label="EV make/model (optional)"
              :menu-props="{ contentClass: 'pwt-scrollbar-styles' }"
              return-object
              clearable
              @change="handleModelSelect"
            ></v-autocomplete>
            <v-text-field
              v-if="fuelType === 'Electric'"
              v-model="batterySize"
              label="Battery size (required)"
              type="number"
              clearable
              :rules="[validateBattery]"
              suffix="kWh"
            ></v-text-field>
            <v-slider
              v-if="fuelType === 'Electric'"
              :label="'Range adjustment ' + SOH + '%'"
              track-color="grey-lighten-2"
              v-model="SOH"
              min="1"
              max="100"
              class="pr-2 pb-3"
              :disabled="!!processingStatus"
              :messages="batteryAgeMessage"
              step="1"
              color="primary"
              thumb-size="16"
              track-size="6"
            ></v-slider>
            <v-autocomplete
              label="Connectors (required)"
              v-if="fuelType === 'Electric'  || fuelType === 'Hydrogen'"
              chips
              clearable
              multiple
              :menu-props="{ contentClass: 'pwt-scrollbar-styles' }"
              return-object
              v-model="selectedConnectors"
              :items="connectors"
              item-title="displayName"
              :rules="[validateSelectedConnectors]"
              :disabled="fuelType === 'Hydrogen'"
            ></v-autocomplete>
            <p>
              The following advanced settings are only optional and only suggested to be changed if
              you know what you are doing.
            </p>
            <p>
              The EV make/model has preset values for these settings. You only need change these if
              your vehicle differs from those.
            </p>
            <v-text-field
              v-if="fuelType === 'Electric' || fuelType === 'Hydrogen'"
              v-model="Mass"
              label="Vehicle weight (optional)"
              type="number"
              clearable
              suffix="kg"
            ></v-text-field>
            <v-text-field
              v-if="fuelType === 'Electric' || fuelType === 'Hydrogen'"
              v-model="DragCoefficient"
              label="Aerodynamics (optional)"
              type="number"
              clearable
              :messages="dragCoefficientMessage"
              :rules="[validateDrag]"
            ></v-text-field>
            <v-text-field
              v-if="fuelType === 'Electric'  || fuelType === 'Hydrogen'"
              v-model="RollingResistanceCoefficient"
              label="Road dynamics (optional)"
              type="number"
              clearable
              :rules="[validateRoleResistance]"
            ></v-text-field>
            <v-text-field
              v-if="fuelType === 'Electric'  || fuelType === 'Hydrogen'"
              v-model="RegenerativeBreakingEfficiency"
              label="Regenerative breaking efficiency (optional)"
              type="number"
              clearable
              :rules="[validateRegenBreaking]"
              :messages="regenerativeBrakingMessage"
              suffix="%"
            ></v-text-field>
            <v-text-field
              v-if="fuelType === 'Electric'  || fuelType === 'Hydrogen'"
              v-model="PowerChainEfficiency"
              label="Power chain efficiency (optional)"
              type="number"
              clearable
              :rules="[validatePowerChainEfficiency]"
              :message="powerChainEfficiencyMessage"
              suffix="%"
            ></v-text-field>
            <v-text-field
              v-if="fuelType === 'Electric'"
              v-model="MaxElectricPowerAc"
              label="AC charging speed (optional)"
              type="number"
              clearable
              :rules="[validateMaxAC]"
              suffix="kw"
            ></v-text-field>
            <v-text-field
              v-if="fuelType === 'Electric'"
              v-model="MaxElectricPowerDc"
              label="DC charging speed (optional)"
              type="number"
              clearable
              :rules="[validateMaxDC]"
              suffix="kw"
            ></v-text-field>
          </v-tabs-window-item>
        </v-tabs-window>
      </v-form>
    </v-card-text>
    <v-card-actions class="px-5">
      <ElevatedBtn
        @click="handleSubmit"
        :loading="!!processingStatus"
        :disabled="!!processingStatus || !valid"
      >
        Save
      </ElevatedBtn>
      <ElevatedBtn @click="handleReset" :disabled="!!processingStatus">
        {{ saveStatus ? 'Add another' : 'Reset' }}
      </ElevatedBtn>
      <ElevatedBtn @click="handleCancel" :disabled="!!processingStatus">
        {{ saveStatus ? 'Close' : 'Cancel' }}
      </ElevatedBtn>
    </v-card-actions>
    <v-alert type="success" v-if="processingStatus === 'SUCCESS'" class="mx-2">
      Vehicle created successfully and saved to the database
    </v-alert>
    <v-alert type="error" v-if="processingStatus === 'FAILED'" class="mx-2">
      Vehicle was created locally but failed to be saved to the database
    </v-alert>
  </v-card>
</template>

<script setup lang="ts">
import type EVModel from '@/classes/vehicle_classes/evModel'
import Vehicle from '@/classes/vehicle_classes/vehicle'
import {
  connectorDetailsDataMap,
  getAllTetheredConnectors,
  type ConnectorDetailsData,
} from '@/data/connectorDetailsData'
import evNavDefaultData from '@/data/eVNavDefaultData'
import { ActionTypes, MutationTypes } from '@/store/store_types'
import type { FuelType } from '@/types/sheared_local_types'
import parseIntOrFloat from '@/utils/parseIntOrFloat'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import ElevatedBtn from '../ui-elements/buttons/ElevatedBtn.vue'

const tab = ref(0)
const evModel = ref<EVModel | null>(null)
const batterySize = ref<string | number | null>(null)
const SOH = ref(100)
const fuelType = ref<FuelType>('Electric')
const vehicleName = ref<string | null>(null)
const plate = ref<string | null>(null)
const VIN = ref<string | null>(null)
const Mass = ref<string | number | null>(evNavDefaultData.Mass)
const DragCoefficient = ref<string | number | null>(evNavDefaultData.DragCoefficient)
const RollingResistanceCoefficient = ref<string | number | null>(
  evNavDefaultData.RollingResistanceCoefficient,
)
const RegenerativeBreakingEfficiency = ref<string | number | null>(
  evNavDefaultData.RegenerativeBreakingEfficiency * 100,
)
const PowerChainEfficiency = ref<string | number | null>(
  evNavDefaultData.PowerChainEfficiency * 100,
)
const MaxElectricPowerAc = ref<string | number | null>(evNavDefaultData.MaxElectricPowerAc)
const MaxElectricPowerDc = ref<string | number | null>(evNavDefaultData.MaxElectricPowerDc)
const selectedConnectors = ref<ConnectorDetailsData[]>([])
const saveStatus = ref<undefined | 'failed' | 'success'>(undefined)

const store = useStore()

const batteryAgeMessage = computed(() => {
  const degradedPercentage = 100 - SOH.value
  if (degradedPercentage >= 70) return 'this looks like a poorly functioning battery'
  const yearsOld = Math.floor(degradedPercentage / 3)
  if (yearsOld <= 0) return 'equivalent to a new battery'
  return 'equivalent to a ' + yearsOld + ' year old battery with normal usage'
})

const dragCoefficientMessage = computed(() => {
  if (DragCoefficient.value) {
    const value = parseIntOrFloat(DragCoefficient.value)
    if (value && value >= 10)
      return 'Are you sure? This is the equivalent of being as aerodynamic as a wall.'
  }
  return undefined
})

const regenerativeBrakingMessage = computed(() => {
  if (RegenerativeBreakingEfficiency.value) {
    const value = parseIntOrFloat(RegenerativeBreakingEfficiency.value)
    if (value && value >= 100) return 'Wow! Really?'
  }
  return undefined
})

const powerChainEfficiencyMessage = computed(() => {
  if (PowerChainEfficiency.value) {
    const value = parseIntOrFloat(PowerChainEfficiency.value)
    if (value && value >= 100) return 'Wow! Really?'
  }
  return undefined
})

const connectors = computed(() => getAllTetheredConnectors())

const EVModels = computed(() => store.state.evModels)
const companyDirectusID = computed(() => store.state.user?.companyDirectusId)
const driverDirectusID = computed(() => store.state.user?.driverDirectusId)
const processingStatus = computed(() => store.state.vehicleSavingStatus)

watch(fuelType, (val) => {
  if (val === 'Hydrogen') {
    const hydrogenConnector = connectorDetailsDataMap.get('HYDROGEN')
    if (hydrogenConnector && selectedConnectors.value.every((c) => c.standard !== 'HYDROGEN')) {
      selectedConnectors.value.push(hydrogenConnector)
    }
  }
})

const valid = ref(true)

async function handleSubmit(): Promise<void> {
  // validate the form
  if (!valid) {
    return // exit early as failed validation.
  }

  // create new vehicle class object
  const newVehicle = Vehicle.fromFormData({
    batterySize: batterySize.value,
    DragCoefficient: DragCoefficient.value,
    evModel: evModel.value,
    fuelType: fuelType.value,
    Mass: Mass.value,
    MaxElectricPowerAc: MaxElectricPowerAc.value,
    MaxElectricPowerDc: MaxElectricPowerDc.value,
    plate: plate.value,
    PowerChainEfficiency: PowerChainEfficiency.value,
    RegenerativeBreakingEfficiency: RegenerativeBreakingEfficiency.value,
    RollingResistanceCoefficient: RollingResistanceCoefficient.value,
    selectedConnectors: selectedConnectors.value,
    SOH: SOH.value,
    vehicleName: vehicleName.value,
    VIN: VIN.value,
    companyDirectusID: companyDirectusID.value,
    driverDirectusID: driverDirectusID.value,
  })

  // update DB
  // this.saveStatus = await newVehicle.saveVehicle();
  store.dispatch(ActionTypes.saveVehicle, newVehicle)

  // add to global state
  store.commit(MutationTypes.addIndividualVehicle, newVehicle)

  // indicate process has finished
}

function handleCancel(): void {
  store.commit(MutationTypes.setMainDialogContent, undefined)
}

function handleModelSelect(val: EVModel | null): void {
  if (val) {
    fuelType.value = parseModelFuelType(val.fuelType)
    batterySize.value = val.batterySize
    Mass.value = val.mass
    DragCoefficient.value = val.dragCoefficient
    RollingResistanceCoefficient.value = evNavDefaultData.RollingResistanceCoefficient
    RegenerativeBreakingEfficiency.value = val.regenRecovery * 100 // convert from decimal percentage representation to whole number percentage representation.
    PowerChainEfficiency.value = val.powerChainEfficiency * 100 // convert from decimal percentage representation to whole number percentage representation.
    MaxElectricPowerAc.value = val.maxElectricPowerAC
    MaxElectricPowerDc.value = val.maxElectricPowerDC
    SOH.value = val.calcLinearDegradationSOH() * 100 // convert from decimal percentage representation to whole number percentage representation.
    // connectors
    const tempArray: ConnectorDetailsData[] = []
    val.compatibleConnectors.forEach((connector) => {
      const data = connectors.value.find((dataItem) => dataItem.standard === connector.standard)
      const alreadyAdded = !!tempArray.find((dataItem) => dataItem.standard === connector.standard)
      if (data && !alreadyAdded) tempArray.push(data)
    })
    selectedConnectors.value = tempArray
  }
}

function parseModelFuelType(fuelType: 'PETROL' | 'ELECTRIC' | 'DIESEL' | 'HYDROGEN'): FuelType {
  switch (fuelType) {
    case 'DIESEL':
      return 'Diesel';
    case 'ELECTRIC':
      return 'Electric'
    case 'HYDROGEN':
      return 'Hydrogen'
    case 'PETROL':
      return 'Petrol'
  }
}

function handleReset(): void {
  evModel.value = null
  batterySize.value = null
  SOH.value = 100
  fuelType.value = 'Electric'
  vehicleName.value = null
  plate.value = null
  VIN.value = null
  Mass.value = evNavDefaultData.Mass
  DragCoefficient.value = evNavDefaultData.DragCoefficient
  RollingResistanceCoefficient.value = evNavDefaultData.RollingResistanceCoefficient
  RegenerativeBreakingEfficiency.value = evNavDefaultData.RegenerativeBreakingEfficiency * 100 // convert from decimal percentage representation to whole number percentage representation.
  PowerChainEfficiency.value = evNavDefaultData.PowerChainEfficiency * 100 // convert from decimal percentage representation to whole number percentage representation.
  MaxElectricPowerAc.value = evNavDefaultData.MaxElectricPowerAc
  MaxElectricPowerDc.value = evNavDefaultData.MaxElectricPowerDc
  selectedConnectors.value = []
  saveStatus.value = undefined
}

function validateSelectedConnectors(v: ConnectorDetailsData[]): boolean | string {
  if (fuelType.value !== 'Electric') return true // no need to validate connectors if not an EV.
  if (v.length) return true
  return 'Please select at least one connector'
}

function validateFuelType(v: string | number | null): boolean | string {
  if (v) return true
  return 'Fuel type is required'
}

function validateBattery(v: string | number | null): boolean | string {
  if (fuelType.value !== 'Electric') return true // no need to validate connectors if not an EV.
  if (v) return true
  return 'An EV needs to have a battery'
}

function validateDrag(v: string | number | null): boolean | string {
  if (fuelType.value !== 'Electric') return true // no need to validate connectors if not an EV.
  if (!v) return true // is only an optional property so if not set needs no range checking.
  const value = parseIntOrFloat(v)
  if ((value && value < 0.01) || (value && value > 10))
    return 'Please set a value between 0.01 and 10 if setting aerodynamics'
  return true
}

function validateRoleResistance(v: string | number | null): boolean | string {
  if (fuelType.value !== 'Electric') return true // no need to validate connectors if not an EV.
  if (!v) return true // is only an optional property so if not set needs no range checking.
  const value = parseIntOrFloat(v)
  if ((value && value < 0.001) || (value && value > 1))
    return 'Please set a value between 0.001 and 1 if setting road dynamics'
  return true
}

function validateRegenBreaking(v: string | number | null): boolean | string {
  if (fuelType.value !== 'Electric') return true // no need to validate connectors if not an EV.
  if (!v) return true // is only an optional property so if not set needs no range checking.
  const value = parseIntOrFloat(v)
  if ((value && value < 1) || (value && value > 100))
    return 'Please set a value between 1% and 100% if setting regenerative breaking efficiency'
  return true
}

function validatePowerChainEfficiency(v: string | number | null): boolean | string {
  if (fuelType.value !== 'Electric') return true // no need to validate connectors if not an EV.
  if (!v) return true // is only an optional property so if not set needs no range checking.
  const value = parseIntOrFloat(v)
  if ((value && value < 1) || (value && value > 100))
    return 'Please set a value between 1% and 100% if setting power chain efficiency'
  return true
}

function validateMaxAC(v: string | number | null): boolean | string {
  if (fuelType.value !== 'Electric') return true // no need to validate connectors if not an EV.
  if (!v) return true // is only an optional property so if not set needs no range checking.
  const value = parseIntOrFloat(v)
  if ((value && value < 0) || (value && value > 50))
    return 'Please set a value between 0kw and 50kw if setting AC charging speed'
  return true
}

function validateMaxDC(v: string | number | null): boolean | string {
  if (fuelType.value !== 'Electric') return true // no need to validate connectors if not an EV.
  if (!v) return true // is only an optional property so if not set needs no range checking.
  const value = parseIntOrFloat(v)
  if ((value && value < 0) || (value && value > 1000))
    return 'Please set a value between 0kw and 1000kw if setting DC charging speed'
  return true
}
</script>

<style scoped>
.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}

.pwt-tabs-border {
  border-bottom: 2px solid rgb(var(--v-theme-primary));
}

.pwt-custom-active-tab-text {
  z-index: 1500;
}

* :deep(.v-tab__slider) {
  border-radius: 10px 10px 0 0;
  background-color: rgb(var(--v-theme-primary));
  height: 47px;
}

* :deep(.v-slider--horizontal) {
  margin-left: unset;
  margin-right: unset;
}

* :deep(.v-slider__track-fill) {
  border-radius: 2px; /* override default slider border-radius */
}

* :deep(.v-slider__track-background) {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
