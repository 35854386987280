import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import { ALL_CHARGERS_ENDPOINT } from '@/api/clients/charger-database-client'
import type { Location as apiLocation } from '@/types/charger_Db_types'

/**
 * Fetches all chargers from the charger database.
 *
 * @returns A promise that resolves with the full list of charger records from the charger database or undefined if an error occurs.
 */
export default async function fetchChargers(): Promise<apiLocation[] | undefined> {
  try {
    const response = await fetch(ALL_CHARGERS_ENDPOINT, { method: 'GET' })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const result = await response.text()
    return JSON.parse(result)
  } catch (error) {
    if (error instanceof SyntaxError) {
      // If JSON parsing fails, rethrow the error
      throw error
    } else {
      notifyAnalytics({ type: 'log_error', data: error })
      return undefined
    }
  }
}
