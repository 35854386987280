<template>
  <v-col cols="12" sm="12" md="6" :order="isSelected ? 'first' : undefined">
    <v-card
      height="100%"
      :max-height="vehicle ? '350px' : '250px'"
      class="pt-3"
      :class="
        isSelected ? 'd-flex flex-column bg-primary rounded-lg' : 'd-flex flex-column rounded-lg'
      "
      @click.stop="showDetails"
      :style="vehicle ? '' : 'cursor: unset;'"
      elevation="3"
    >
      <v-row no-gutters class="align-center">
        <v-col cols="4" class="pl-2">
          <v-img
            :lazy-src="getSrc('car_images/No_Image_Powersell.png')"
            :src="getImageSrc()"
            contain
          />
        </v-col>
        <v-col cols="8" style="min-height: 70px">
          <v-card-title
            v-if="vehicle.vehicleType !== 'GENERIC_VEHICLE'"
            :class="isSelected ? 'text-white' : ''"
          >
            {{ vehicle.licensePlate ? vehicle.licensePlate : 'no plate' }}
          </v-card-title>
          <v-card-subtitle :class="isSelected ? 'text-white pb-0' : 'pb-0'">
            {{ vehicle.evModel ? vehicle.evModel.name : 'model not specified' }}
          </v-card-subtitle>
          <v-card-subtitle
            :class="isSelected ? 'text-white py-0' : 'py-0'"
            v-if="vehicle.stateOfCharge"
          >
            {{ vehicle.stateOfCharge + '% Charge' }}
          </v-card-subtitle>
          <v-card-text
            v-if="selectedTrip && vehicle && vehicle.latitude && vehicle.longitude"
            :class="isSelected ? 'text-white' : ''"
          >
            {{ calcDistance([vehicle.longitude, vehicle.latitude], selectedTrip) }}
            from start
          </v-card-text>
        </v-col>        
      </v-row>
      <v-card-actions class="mt-auto">
        <v-card class="rounded-lg flex-grow-1 d-flex" v-if="isSelected">
          <v-card-text class="pa-2 text-center">Selected</v-card-text>
        </v-card>
        <ElevatedBlockBtn @click="select" v-else> Select </ElevatedBlockBtn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script setup lang="ts">
import Trip from '@/classes/trip_classes/trip'
import Vehicle from '@/classes/vehicle_classes/vehicle'
import { GettersTypes } from '@/store/store_types'
import getAssetSrc from '@/utils/getAssetSrc'
import getDirectusImgUrl from '@/utils/getDirectusImgUrl'
import haversineDistance from '@/utils/haversineDistance'
import { computed } from 'vue'
import { useStore } from 'vuex'
import ElevatedBlockBtn from '../ui-elements/buttons/ElevatedBlockBtn.vue'

const store = useStore()

const { vehicle } = defineProps<{ vehicle: Vehicle }>()

const emit = defineEmits<{
  (e: 'select', value: Vehicle): void
  (e: 'showDetails', value: Vehicle): void
}>()

function select() {
  emit('select', vehicle)
}

function showDetails() {
  emit('showDetails', vehicle)
}

function calcDistance(vehicleCoordinate: [number, number], tripData: Trip) {
  const tripStartCoordinate: [number, number] = [
    tripData.locations[0].coordinates.longitude,
    tripData.locations[0].coordinates.latitude,
  ]

  const distance = haversineDistance(vehicleCoordinate, tripStartCoordinate)
  return `${Math.round(distance)}km`
}

function getImageSrc() {
  if (vehicle.imageSrcPaths.length) return vehicle.imageSrcPaths[0]
  if (vehicle.evModel?.imageUUID) return getDirectusImgUrl(vehicle.evModel.imageUUID)
  return getSrc('car_images/No_Image_Powersell.png')
}

function getSrc(partialFilePath: string): string {
  return getAssetSrc(partialFilePath)
}

const isSelected = computed(() => store.state.selectedVehicle === vehicle.localId)

const selectedTrip = computed(() => store.getters[GettersTypes.selectedTripData])
</script>
