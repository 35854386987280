<template>
  <v-card-text>
    <v-card class="rounded-lg mb-3">
      <v-card-title class="my-5 d-flex">
        <v-icon color="primary" class="mr-3"> mdi-piggy-bank-outline </v-icon>
        ${{
          Math.round(
            (oneYearProjections.totalEquivalentFuelCost +
              (calcVs === 'PETROL'
                ? oneYearProjections.totalPetrolRoadUserCharges
                : oneYearProjections.totalDieselRoadUserCharges) +
              oneYearProjections.totalPetrolMaintenanceCost -
              oneYearProjections.totalPublicChargingCost -
              oneYearProjections.totalPrivateChargingCost -
              oneYearProjections.totalEVMaintenanceCost -
              oneYearProjections.totalElectricRoadUserCharges) *
              years,
          ).toLocaleString()
        }}
        total savings
        <v-spacer />
        <TextBtn @click="showFilters = true"> Filter trips included in cost estimate </TextBtn>
      </v-card-title>
      <v-card-text>
        <v-slider
          min="1"
          max="10"
          step="1"
          v-model="years"
          dense
          hide-details
          track-color="grey-lighten-2"
          class="mb-5"
          :label="years + ' year' + (years > 1 ? 's' : '')"
          color="primary"
          thumb-size="16"
          track-size="6"
        />
      </v-card-text>

      <v-row class="flex-column flex-md-row">
        <v-col md="6">
          <v-card flat>
            <v-card-title> Emissions avoided </v-card-title>
            <v-card-text>
              <v-card class="bg-background" flat>
                <v-icon color="primary" class="mr-1 ml-2 py-2"> mdi-fire </v-icon>
                <span>
                  {{
                    Math.round(oneYearProjections.totalAvoidedEmissions * years).toLocaleString()
                  }}
                  kg CO₂ emissions avoided
                </span>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="6">
          <v-card flat>
            <v-card-title> Total trips </v-card-title>
            <v-card-text>
              <v-card class="bg-background" flat>
                <v-icon color="primary" class="mr-1 ml-2 py-2"> mdi-map-marker-outline </v-icon>
                <span>
                  {{ Math.round(oneYearProjections.totalNumberOfTrips * years).toLocaleString() }}
                  total trips
                </span>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="flex-column flex-md-row">
        <v-col md="6">
          <v-card flat>
            <v-card-title> Fuel savings </v-card-title>
            <v-card-text>
              <v-card class="bg-background mb-3" flat>
                <v-icon color="primary" class="mr-1 ml-2 py-2"> mdi-piggy-bank-outline </v-icon>
                <span>
                  ${{
                    Math.round(
                      (oneYearProjections.totalEquivalentFuelCost +
                        oneYearProjections.totalPublicChargingCost -
                        oneYearProjections.totalPrivateChargingCost) *
                        years,
                    ).toLocaleString()
                  }}
                  fuel savings
                </span>
              </v-card>
              <v-card class="bg-background mb-3" flat>
                <v-icon color="primary" class="mr-1 ml-2 py-2"> mdi-water-outline </v-icon>
                <span>
                  ${{
                    Math.round(oneYearProjections.totalEquivalentFuelCost * years).toLocaleString()
                  }}
                  equivalent fuel cost
                </span>
              </v-card>
              <v-card class="bg-background mb-3" flat>
                <v-icon color="primary" class="mr-1 ml-2 py-2"> mdi-currency-usd </v-icon>
                <span>
                  ${{
                    Math.round(oneYearProjections.totalPublicChargingCost * years).toLocaleString()
                  }}
                  spent on public charging
                </span>
              </v-card>
              <v-card class="bg-background" flat>
                <v-icon color="primary" class="mr-1 ml-2 py-2"> mdi-currency-usd </v-icon>
                <span>
                  ${{
                    Math.round(oneYearProjections.totalPrivateChargingCost * years).toLocaleString()
                  }}
                  spent on private charging
                </span>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="6">
          <v-card flat>
            <v-card-title> Maintenance savings </v-card-title>
            <v-card-text>
              <v-card class="bg-background mb-3" flat>
                <v-icon color="primary" class="mr-1 ml-2 py-2"> mdi-piggy-bank-outline </v-icon>
                <span>
                  ${{
                    Math.round(
                      (oneYearProjections.totalPetrolMaintenanceCost -
                        oneYearProjections.totalEVMaintenanceCost) *
                        years,
                    ).toLocaleString()
                  }}
                  maintenance savings
                </span>
              </v-card>
              <v-card class="bg-background mb-3" flat>
                <v-icon color="primary" class="mr-1 ml-2 py-2"> mdi-pliers </v-icon>
                <span>
                  ${{
                    Math.round(
                      oneYearProjections.totalPetrolMaintenanceCost * years,
                    ).toLocaleString()
                  }}
                  petrol vehicle maintenance
                </span>
              </v-card>
              <v-card class="bg-background" flat>
                <v-icon color="primary" class="mr-1 ml-2 py-2"> mdi-pliers </v-icon>
                <span>
                  ${{
                    Math.round(oneYearProjections.totalEVMaintenanceCost * years).toLocaleString()
                  }}
                  electric vehicle maintenance
                </span>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-card flat>
        <v-card-title> Road user charges </v-card-title>
        <v-card-text>
          <v-card class="bg-background mb-3" flat>
            <v-icon color="primary" class="mr-1 ml-2 py-2"> mdi-currency-usd </v-icon>
            <span>
              ${{
                Math.round(oneYearProjections.totalElectricRoadUserCharges * years).toLocaleString()
              }}
              electric road user charges
            </span>
          </v-card>
          <v-card class="bg-background mb-3" flat>
            <v-icon color="primary" class="mr-1 ml-2 py-2"> mdi-currency-usd </v-icon>
            <span>
              ${{
                Math.round(oneYearProjections.totalPetrolRoadUserCharges * years).toLocaleString()
              }}
              equivalent petrol road user charges
            </span>
          </v-card>
          <v-card class="bg-background" flat>
            <v-icon color="primary" class="mr-1 ml-2 py-2"> mdi-currency-usd </v-icon>
            <span>
              ${{
                Math.round(oneYearProjections.totalDieselRoadUserCharges * years).toLocaleString()
              }}
              equivalent diesel road user charges
            </span>
          </v-card>
        </v-card-text>
      </v-card>
    </v-card>
    <GenericDialog :activator="showFilters" @close="showFilters = false">
      <GroupSelectionContent @close="showFilters = false" />
    </GenericDialog>
  </v-card-text>
</template>
<script lang="ts" setup>
import GenericDialog from '@/components/dialog-wrappers/GenericDialog.vue'
import TextBtn from '@/components/ui-elements/buttons/TextBtn.vue'
import { GettersTypes, type State } from '@/store/store_types'
import type { YearsSavings } from '@/utils/calcYearsSavings'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import GroupSelectionContent from '../history/GroupSelectionContent.vue'

const store = useStore<State>()

const oneYearProjections = computed<YearsSavings>(
  () => store.getters[GettersTypes.getOneYearProjections],
)
const calcVs = computed(() => store.state.calcVs)
const years = ref(5)
const showFilters = ref(false)
</script>
<style scoped>
* :deep(.v-slider--horizontal .v-slider__track-container) {
  height: 6px; /* override default slider thickness */
}

* :deep(.v-slider__track-background) {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
