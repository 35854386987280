import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import fetchChargers from '@/api/calls/charger-database-calls/charger-calls'
import fetchEVModels from '@/api/calls/charger-database-calls/ev-models-calls'
import { logout } from '@/api/calls/directus-calls/auth-calls'
import { fetchDriverData } from '@/api/calls/directus-calls/driver-calls'
import { fetchFavouriteLocations } from '@/api/calls/directus-calls/favourite-locations-calls'
import { deleteDirectusFile } from '@/api/calls/directus-calls/file-calls'
import { fetchFleetConfigData, fetchFleetData } from '@/api/calls/directus-calls/fleet-calls'
import { fetchManagedContent } from '@/api/calls/directus-calls/managed-content-calls'
import { fetchNetworkBranding } from '@/api/calls/directus-calls/network-branding-calls'
import {
  fetchSettings,
  updateSettingsRecord,
} from '@/api/calls/directus-calls/optimiser-settings-calls'
import {
  deleteSavedRoutePlans,
  fetchSavedRoutePlansByUser,
} from '@/api/calls/directus-calls/saved-route-plans'
import { fetchUser, updateUser } from '@/api/calls/directus-calls/user-calls'
import {
  fetchEVModelImageLinks,
  fetchFleetVehicles,
} from '@/api/calls/directus-calls/vehicle-calls'
import { fetchGeoIPDetails } from '@/api/calls/maps-calls/geo-ip-calls'
import Charger from '@/classes/charger_classes/charger'
import NetworkBranding from '@/classes/charger_classes/networkBranding'
import Coordinate from '@/classes/common_classes/coordinate'
import FavouriteLocation from '@/classes/favouriteLocation'
import Fleet from '@/classes/fleet'
import Trip from '@/classes/trip_classes/trip'
import TripLocation from '@/classes/trip_classes/tripLocation'
import Driver from '@/classes/user_classes/driver'
import User from '@/classes/user_classes/user'
import EVModel from '@/classes/vehicle_classes/evModel'
import Vehicle from '@/classes/vehicle_classes/vehicle'
import evNavDefaultData from '@/data/eVNavDefaultData'
import OptimiserDefaultData from '@/data/optimiserDefaultData'
import router, { RouteNames } from '@/router'
import type { CDB_EVModel } from '@/types/charger_Db_types'
import type {
  DirectusDataToUpdate,
  DirectusManagedContent,
  DirectusSettings
} from '@/types/directus-types'
import type { SortedTripsGroup } from '@/types/sheared_local_types'
import type { TripStats } from '@/types/trip_specific_types'
import { VehicleType } from '@/types/vehicle_specific_types'
import type { YearsSavings } from '@/utils/calcYearsSavings'
import calcYearsSavings from '@/utils/calcYearsSavings'
import parseIntOrFloat from '@/utils/parseIntOrFloat'
import type { processedAddressObj } from '@/utils/processAddressSearchResults'
import { createStore, type Commit, type Dispatch } from 'vuex'
import {
  ActionTypes,
  AsyncStatus,
  CalcVsType,
  ChargerFilters,
  GettersTypes,
  MainDialogContent,
  MutationTypes,
  SecondaryDialogContent,
  TertiaryDialogContent,
  type BatchUpdatableSettings,
  type DashboardDialogContent,
  type State,
  type UploadVehicleImagePayload,
} from './store_types'

// Create a new store instance.
const store = createStore({
  state(): State {
    return {
      // Class object data.
      user: undefined,
      driver: undefined,
      userGeoIPData: undefined,
      fleet: undefined,
      chargers: [],
      trips: [],
      evModels: [],
      networkBranding: [],
      vehicles: [],
      managedContent: [],
      favLocations: [],
      // Ui viewing object data selections.
      selectedTrip: undefined,
      selectedVehicle: undefined,
      selectedCharger: undefined,
      selectedLocation: undefined,
      queuedTripForDelete: undefined,
      viewedFleetVehicle: undefined,
      tripsGroups: undefined,
      addressSearchHistory: [],
      // async statuses.
      authStatus: undefined,
      updateImageStatus: undefined,
      deleteTripStatus: undefined,
      routePlanningStatus: undefined,
      tripSavingStatus: undefined,
      updatingUserStatus: undefined,
      vehicleSavingStatus: undefined,
      radarFetchingFlag: false,
      settingsSavingStatus: undefined,
      // Ui flags.
      settingsFetchingFlag: true,
      chargerFetchingFlag: true,
      userFetchingFlag: true,
      fleetFetchingFlag: true,
      tripFetchingFlag: true,
      checkingToken: true,
      authorizedFlag: false,
      routePlanningFlag: false,
      showLogin: true,
      mainDialogContent: undefined,
      secondaryDialogContent: undefined,
      tertiaryDialogContent: undefined,
      dashboardDialogContent: 'home',
      showDashboard: false,
      displayAllChargersAlongRoute: false,
      displayLongestStep: false,
      showPrivateACChargers: true,
      showPrivateDCChargers: true,
      showPublicACChargers: true,
      showPublicDCChargers: true,
      chargersSearchCriteria: undefined,
      vehiclesSearchCriteria: undefined,
      displayConnectedVehicles: false,
      pannedCenter: undefined,
      infoPanelAnimation: undefined,
      // settings.
      settingsTableID: undefined,
      SOCMax: OptimiserDefaultData.SOCMax,
      SOCMin: OptimiserDefaultData.SOCMin,
      SOCAct: OptimiserDefaultData.SOCAct,
      SOCEnd: OptimiserDefaultData.SOCEnd,
      defaultHomeCostPerKWh: OptimiserDefaultData.kWhCostHome,
      defaultPublicCostPerKWh: OptimiserDefaultData.kWhCostPublic,
      defaultCostPerMinDC: OptimiserDefaultData.electricityCostPerMinPublic,
      networkSelectionOverride: undefined,
      dieselCostPerLitre: OptimiserDefaultData.dieselCostPerLitre,
      dieselKmPerLitre: OptimiserDefaultData.dieselKmPerLitre,
      extraWeight: undefined,
      perAnnumMaintenanceCurrentV: OptimiserDefaultData.perAnnumMaintenanceCurrentV,
      perAnnumMaintenanceEV: OptimiserDefaultData.perAnnumMaintenanceEV,
      petrolCostPerLitre: OptimiserDefaultData.petrolCostPerLitre,
      petrolKmPerLitre: OptimiserDefaultData.petrolKmPerLitre,
      relativeSpeed: OptimiserDefaultData.relativeSpeed,
      dieselRoadUserCharges: OptimiserDefaultData.roadUserCharges,
      petrolRoadUserCharges: 0,
      electricRoadUserCharges: OptimiserDefaultData.roadUserCharges,
      calcVs: CalcVsType.PETROL,
      acceleration: evNavDefaultData.AccelerationAdjustment,
      passengers: 0,
    }
  },
  mutations: {
    // Class object data mutators.
    [MutationTypes.setUser](state: State, payload: User | undefined) {
      state.user = payload
    },
    [MutationTypes.setDriver](state: State, payload: Driver | undefined) {
      state.driver = payload
    },
    [MutationTypes.setFleet](state: State, payload: Fleet | undefined) {
      state.fleet = payload
    },
    [MutationTypes.setChargers](state: State, payload: Charger[]) {
      state.chargers = payload
    },
    [MutationTypes.updateIndividualCharger](state: State, payload: Charger) {
      state.chargers = [...state.chargers.filter((charger) => charger.id !== payload.id), payload]
    },
    [MutationTypes.setEvModels](state: State, payload: EVModel[]) {
      state.evModels = payload
    },
    [MutationTypes.setTrips](state: State, payload: Trip[]) {
      state.trips = payload
    },
    [MutationTypes.updateIndividualTrip](state: State, payload: Trip) {
      state.trips = [...state.trips.filter((trip) => trip.localId !== payload.localId), payload]
    },
    [MutationTypes.setNetworkBranding](state: State, payload: NetworkBranding[]) {
      state.networkBranding = payload
    },
    [MutationTypes.setUserGeoIPData](state: State, payload: Coordinate | undefined) {
      state.userGeoIPData = payload
    },
    [MutationTypes.setManagedContent](state: State, payload: DirectusManagedContent[]) {
      state.managedContent = payload
    },
    [MutationTypes.updateIndividualDriver](state: State, payload: Driver | undefined) {
      state.driver = payload
    },
    [MutationTypes.setVehicles](state: State, payload: Vehicle[]) {
      state.vehicles = payload
    },
    [MutationTypes.updateIndividualVehicle](state: State, payload: Vehicle) {
      state.vehicles = [
        ...state.vehicles.filter((vehicle) => vehicle.localId !== payload.localId),
        payload,
      ]
    },
    [MutationTypes.addIndividualVehicle](state: State, payload: Vehicle) {
      state.vehicles = [...state.vehicles, payload]
    },
    [MutationTypes.setFavLocations](state: State, payload: FavouriteLocation[]) {
      state.favLocations = payload
    },
    [MutationTypes.updateIndividualFavLocation](state: State, payload: FavouriteLocation) {
      state.favLocations = [
        ...state.favLocations.filter((favLocation) => favLocation.localId !== payload.localId),
        payload,
      ]
    },
    [MutationTypes.deleteFavLocation](state: State, payload: string) {
      state.favLocations = state.favLocations.filter(
        (favLocation) => favLocation.localId !== payload,
      )
    },
    // Ui viewing object data selection mutators.
    [MutationTypes.setSelectedCharger](state: State, payload: string | undefined) {
      state.selectedCharger = payload
    },
    [MutationTypes.setSelectedTrip](state: State, payload: string | undefined) {
      state.selectedTrip = payload
    },
    [MutationTypes.setSelectedVehicle](state: State, payload: string | undefined) {
      state.selectedVehicle = payload
    },
    [MutationTypes.setSelectedLocation](state: State, payload: string | undefined) {
      state.selectedLocation = payload
    },
    [MutationTypes.setQueuedTripForDelete](state: State, payload: string | undefined) {
      state.queuedTripForDelete = payload
    },
    [MutationTypes.setViewedFleetVehicle](state: State, payload: string | undefined) {
      state.viewedFleetVehicle = payload
    },
    [MutationTypes.setTripsGroups](state: State, payload: SortedTripsGroup[] | undefined) {
      state.tripsGroups = payload
    },
    [MutationTypes.setAddressSearchHistory](state: State, payload: processedAddressObj[]) {
      state.addressSearchHistory = payload
    },
    [MutationTypes.addToAddressSearchHistory](state: State, payload: processedAddressObj) {
      state.addressSearchHistory = [...state.addressSearchHistory, payload]
    },
    // async statuses.
    [MutationTypes.setDeleteTripStatus](state: State, payload: AsyncStatus | undefined) {
      state.deleteTripStatus = payload
    },
    [MutationTypes.setUpdateImageStatus](state: State, payload: AsyncStatus | undefined) {
      state.updateImageStatus = payload
    },
    [MutationTypes.setAuthStatus](state: State, payload: AsyncStatus | undefined) {
      state.authStatus = payload
    },
    [MutationTypes.setRoutePlanningStatus](state: State, payload: AsyncStatus | undefined) {
      state.routePlanningStatus = payload
    },
    [MutationTypes.setTripSavingStatus](state: State, payload: AsyncStatus | undefined) {
      state.tripSavingStatus = payload
    },
    [MutationTypes.setVehicleSavingStatus](state: State, payload: AsyncStatus | undefined) {
      state.vehicleSavingStatus = payload
    },
    [MutationTypes.setUpdatingUserStatus](state: State, payload: AsyncStatus | undefined) {
      state.updatingUserStatus = payload
    },
    [MutationTypes.setSettingsSavingStatus](
      state: State,
      payload: 'PROCESSING' | 'SUCCESS' | 'FAILED' | undefined,
    ) {
      state.settingsSavingStatus = payload
    },
    [MutationTypes.setRadarFetchingFlag](state: State, payload: boolean) {
      state.radarFetchingFlag = payload
    },
    // Ui flag mutators.
    [MutationTypes.setFleetFetchingFlag](state: State, payload: boolean) {
      state.fleetFetchingFlag = payload
    },
    [MutationTypes.setSettingsFetchingFlag](state: State, payload: boolean) {
      state.settingsFetchingFlag = payload
    },
    [MutationTypes.setChargerFetchingFlag](state: State, payload: boolean) {
      state.chargerFetchingFlag = payload
    },
    [MutationTypes.setTripFetchingFlag](state: State, payload: boolean) {
      state.tripFetchingFlag = payload
    },
    [MutationTypes.setCheckingToken](state: State, payload: boolean) {
      state.checkingToken = payload
    },
    [MutationTypes.setShowLogin](state: State, payload: boolean) {
      state.showLogin = payload
    },
    [MutationTypes.setUserFetchingFlag](state: State, payload: boolean) {
      state.userFetchingFlag = payload
    },
    [MutationTypes.setAuthorizedFlag](state: State, payload: boolean) {
      state.authorizedFlag = payload
    },
    [MutationTypes.setRoutePlanningFlag](state: State, payload: boolean) {
      state.routePlanningFlag = payload
    },
    [MutationTypes.setMainDialogContent](state: State, payload: MainDialogContent | undefined) {
      state.mainDialogContent = payload
    },
    [MutationTypes.setSecondaryDialogContent](
      state: State,
      payload: SecondaryDialogContent | undefined,
    ) {
      state.secondaryDialogContent = payload
    },
    [MutationTypes.setTertiaryDialogContent](
      state: State,
      payload: TertiaryDialogContent | undefined,
    ) {
      state.tertiaryDialogContent = payload
    },
    [MutationTypes.setDashboardDialogContent](
      state: State,
      payload: DashboardDialogContent | undefined,
    ) {
      state.dashboardDialogContent = payload
    },
    [MutationTypes.setShowDashboard](state: State, payload: boolean) {
      state.showDashboard = payload
    },
    [MutationTypes.setChargersSearchCriteria](state: State, payload: string | undefined) {
      state.chargersSearchCriteria = payload
    },
    [MutationTypes.setVehiclesSearchCriteria](state: State, payload: string | undefined) {
      state.vehiclesSearchCriteria = payload
    },
    [MutationTypes.setShowPrivateACChargers](state: State, payload: boolean) {
      state.showPrivateACChargers = payload
    },
    [MutationTypes.setShowPrivateDCChargers](state: State, payload: boolean) {
      state.showPrivateDCChargers = payload
    },
    [MutationTypes.setShowPublicACChargers](state: State, payload: boolean) {
      state.showPublicACChargers = payload
    },
    [MutationTypes.setShowPublicDCChargers](state: State, payload: boolean) {
      state.showPublicDCChargers = payload
    },
    [MutationTypes.setDisplayAllChargersAlongRoute](state: State, payload: boolean) {
      state.displayAllChargersAlongRoute = payload
    },
    [MutationTypes.setDisplayLongestStep](state: State, payload: boolean) {
      state.displayLongestStep = payload
    },
    [MutationTypes.setDisplayConnectedVehicles](state: State, payload: boolean) {
      state.displayConnectedVehicles = payload
    },
    [MutationTypes.setPannedCenter](
      state: State,
      payload: { lat: number; lng: number } | undefined,
    ) {
      state.pannedCenter = payload
    },
    [MutationTypes.setInfoPanelAnimation](
      state: State,
      payload: 'slide-left' | 'slide-right' | undefined,
    ) {
      state.infoPanelAnimation = payload
    },
    [MutationTypes.setFlyToPoint](state: State, payload: { lat: number; lng: number } | undefined) {
      state.flyToPoint = payload
    },
    // Settings mutators.
    [MutationTypes.setSettingsTableID](state: State, payload: number | undefined) {
      state.settingsTableID = payload
    },
    [MutationTypes.setSOCMax](state: State, payload: number) {
      state.SOCMax = payload
    },
    [MutationTypes.setSOCMin](state: State, payload: number) {
      state.SOCMin = payload
    },
    [MutationTypes.setSOCAct](state: State, payload: number) {
      state.SOCAct = payload
    },
    [MutationTypes.setSOCEnd](state: State, payload: number) {
      state.SOCEnd = payload
    },
    [MutationTypes.setDefaultPublicCostPerKWh](state: State, payload: number) {
      state.defaultPublicCostPerKWh = payload
    },
    [MutationTypes.setDefaultCostPerMinDC](state: State, payload: number) {
      state.defaultCostPerMinDC = payload
    },
    [MutationTypes.setNetworkSelectionOverride](state: State, payload: string[] | undefined) {
      state.networkSelectionOverride = payload
      if (!state.settingsFetchingFlag && state.settingsTableID) {
        if (payload)
          updateSettingsRecord(state.settingsTableID, {
            Network_Selection_Override: JSON.stringify(payload),
          })
        else updateSettingsRecord(state.settingsTableID, { Network_Selection_Override: null })
      }
    },
    [MutationTypes.setDefaultHomeCostPerKWh](state: State, payload: number) {
      state.defaultHomeCostPerKWh = payload
    },
    [MutationTypes.setDieselCostPerLitre](state: State, payload: number) {
      state.dieselCostPerLitre = payload
    },
    [MutationTypes.setDieselKmPerLitre](state: State, payload: number) {
      state.dieselKmPerLitre = payload
    },
    [MutationTypes.setExtraWeight](state: State, payload: number) {
      state.extraWeight = payload
    },
    [MutationTypes.setRelativeSpeed](state: State, payload: number) {
      state.relativeSpeed = payload
    },
    [MutationTypes.setAcceleration](state: State, payload: number) {
      state.acceleration = payload
    },
    [MutationTypes.setPerAnnumMaintenanceCurrentV](state: State, payload: number) {
      state.perAnnumMaintenanceCurrentV = payload
    },
    [MutationTypes.setPerAnnumMaintenanceEV](state: State, payload: number) {
      state.perAnnumMaintenanceEV = payload
    },
    [MutationTypes.setPetrolCostPerLitre](state: State, payload: number) {
      state.petrolCostPerLitre = payload
    },
    [MutationTypes.setPetrolKmPerLitre](state: State, payload: number) {
      state.petrolKmPerLitre = payload
    },
    [MutationTypes.setDieselRoadUserCharges](state: State, payload: number) {
      state.dieselRoadUserCharges = payload
    },
    [MutationTypes.setPetrolRoadUserCharges](state: State, payload: number) {
      state.petrolRoadUserCharges = payload
    },
    [MutationTypes.setElectricRoadUserCharges](state: State, payload: number) {
      state.electricRoadUserCharges = payload
    },
    [MutationTypes.setPassengers](state: State, payload: number) {
      state.passengers = payload
    },
    [MutationTypes.setCalcVs](state: State, payload: CalcVsType) {
      state.calcVs = payload
    },
    [MutationTypes.setSelectedVehicleDirectusID](
      state: State,
      payload: string | number | undefined,
    ) {
      state.selectedVehicleDirectusID = payload
      if (!state.settingsFetchingFlag && state.settingsTableID)
        updateSettingsRecord(state.settingsTableID, { selectedVehicleID: payload })
    },
  },
  actions: {
    // -------------------------------------------------------------------- //
    // ---------------------- data fetching functions --------------------- //
    // -------------------------------------------------------------------- //

    // main fetching call chaining the other fetching methods from it.
    async [ActionTypes.fetchData]({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
      commit(MutationTypes.setUserFetchingFlag, true)
      const userData = await fetchUser()
      if (userData) {
        commit(MutationTypes.setUser, User.fromDirectusData(userData))
        dispatch(ActionTypes.getDriverData)
        dispatch(ActionTypes.getFavLocations)
        dispatch(ActionTypes.getTripsData)
        dispatch(ActionTypes.getSettingsData)
      }
      dispatch(ActionTypes.getFleetData)
      dispatch(ActionTypes.getChargersAndNetworkData)
      const userGeoData = await fetchGeoIPDetails()
      if (userGeoData) {
        commit(
          MutationTypes.setUserGeoIPData,
          new Coordinate({
            latitude: userGeoData.Location.Latitude,
            longitude: userGeoData.Location.Longitude,
          }),
        )
      }
      dispatch(ActionTypes.getManagedContent)
      commit(MutationTypes.setUserFetchingFlag, false)
    },
    // fetch fleet and fleet vehicle data.
    async [ActionTypes.getFleetData]({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
      // Fetch data.
      const [companyData, configData, vehicleData, modelsData, modelImageLinks] = await Promise.all(
        [
          fetchFleetData(),
          fetchFleetConfigData(),
          fetchFleetVehicles(),
          fetchEVModels(),
          fetchEVModelImageLinks(),
        ],
      )
      // Check company data was successfully fetched.
      if (companyData) {
        // convert company into class object.
        const fleet = Fleet.fromDirectusData(companyData)
        if (configData) fleet.config = configData
        // Add company data global state.
        commit(MutationTypes.setFleet, fleet)
        // add company wide settings to global state
        if (fleet.config) {
          if (fleet.config.acCostPerKWh)
            commit(MutationTypes.setDefaultHomeCostPerKWh, fleet.config.acCostPerKWh)
          if (fleet.config.dcCostPerKWh)
            commit(MutationTypes.setDefaultPublicCostPerKWh, fleet.config.dcCostPerKWh)
          if (fleet.config.petrolCostPerLitre)
            commit(MutationTypes.setPetrolCostPerLitre, fleet.config.petrolCostPerLitre)
          if (fleet.config.dieselCostPerLitre)
            commit(MutationTypes.setPetrolKmPerLitre, fleet.config.dieselCostPerLitre)
          if (fleet.config.litresPerKm)
            commit(MutationTypes.setPetrolKmPerLitre, fleet.config.litresPerKm)
        }
      }

      // Check if vehicles data was successfully fetched.
      if (vehicleData) {
        // Add vehicles data to global state.
        commit(
          MutationTypes.setVehicles,
          vehicleData.map((vehicle) => Vehicle.fromDirectusData(vehicle)),
        )
      }

      // Check models data was successfully fetched.
      if (modelsData) {
        // Add models data to global state.
        if (modelImageLinks) {
          // filter out non-electric models that made it into ev models db
          const filteredEvModels = modelsData.filter(
            (model: CDB_EVModel) => model.fuel_type === 'ELECTRIC',
          )
          // Add models data to global state.
          commit(
            MutationTypes.setEvModels,
            filteredEvModels.map((model: CDB_EVModel) => {
              const newModel = EVModel.fromChargerDbData(model)
              const imageLink = modelImageLinks.find((link) => link.CDBID === model.id)
              if (imageLink) newModel.imageUUID = imageLink.Image
              return newModel
            }),
          )
        } else {
          commit(
            MutationTypes.setEvModels,
            modelsData.map((model: CDB_EVModel) => EVModel.fromChargerDbData(model)),
          )
        }

        // Chain next step in fetched data sorting.
        dispatch(ActionTypes.addModelsToFleetVehicles)
        dispatch(ActionTypes.createGenericEVsFromModels)
        // update status
        commit(MutationTypes.setFleetFetchingFlag, false)
      }
    },

    async [ActionTypes.getDriverData]({ commit, state }: { commit: Commit; state: State }) {
      if (state.user?.driverDirectusId) {
        const res = await fetchDriverData(state.user.driverDirectusId)
        if (res) {
          // Add company data global state.
          commit(MutationTypes.updateIndividualDriver, Driver.fromDirectusData(res))
        }
      }
    },
    // fetch chargers and operating network data.
    async [ActionTypes.getChargersAndNetworkData]({ commit }: { commit: Commit }) {
      // create variables to catch data.
      const tempChargersArray: Charger[] = []
      const tempNetworkBrandingArray: NetworkBranding[] = []

      // Fetch data.
      const [chargerData, networkBrandingData] = await Promise.all([
        fetchChargers(),
        fetchNetworkBranding(),
      ])

      // create "NetworkBranding" class objects from data.
      networkBrandingData?.forEach((network) => {
        tempNetworkBrandingArray.push(NetworkBranding.fromDirectusData(network))
      })

      // get list of networks branding has been successfully gathered for.
      const networkList: string[] = []
      tempNetworkBrandingArray.forEach((networkBranding) => {
        networkList.push(networkBranding.networkName)
        networkBranding.aliases.forEach((aliasObj) => {
          networkList.push(aliasObj.alias)
        })
      })

      // create "Charger" class objects from data.
      chargerData?.forEach((charger) => {
        try {
          tempChargersArray.push(Charger.fromChargerDbData(charger))
          // create additional `NetworkBranding` from networks for chargers that don't yet have branding records.
          if (charger.operator?.name && !networkList.includes(charger.operator.name)) {
            tempNetworkBrandingArray.push(
              new NetworkBranding({ networkName: charger.operator.name }),
            )
            networkList.push(charger.operator.name)
          }
        } catch (error) {
          notifyAnalytics({
            type: 'log_error',
            data: error,
          })
        }
      })

      commit(MutationTypes.setChargers, tempChargersArray)
      commit(MutationTypes.setNetworkBranding, tempNetworkBrandingArray)
      // update status
      commit(MutationTypes.setChargerFetchingFlag, false)
    },
    // fetch saved trips and convert into `Trip` class objects.
    async [ActionTypes.getTripsData]({
      commit,
      dispatch,
      state,
    }: {
      commit: Commit
      dispatch: Dispatch
      state: State
    }) {
      if (state.user?.directusId) {
        // fetch data
        const [savedRoutePlans] = await Promise.all([
          fetchSavedRoutePlansByUser(state.user.directusId),
        ])

        // sort data
        const tempArray: Trip[] = []
        if (savedRoutePlans) {
          tempArray.push(...savedRoutePlans.map((trip) => Trip.fromSavedData(trip)))
        }

        // save to sate
        if (tempArray.length) commit(MutationTypes.setTrips, [...state.trips, ...tempArray])

        // Chain next step in fetched data sorting.
        dispatch(ActionTypes.addVehiclesToTrips)
        // update status
        commit(MutationTypes.setTripFetchingFlag, false)
      }
    },
    // fetch settings data and apply to relevant state.
    async [ActionTypes.getSettingsData]({ commit, state }: { commit: Commit; state: State }) {
      if (state.user?.directusId) {
        const fetchedData = await fetchSettings(state.user.directusId)
        if (fetchedData) {
          // overwrite defaults in setting exists/not null.
          if (fetchedData.Maximum_Charge) {
            const parsedValue = parseIntOrFloat(fetchedData.Maximum_Charge) // parsing to cover for directus shift from float to decimal type.
            if (parsedValue) commit(MutationTypes.setSOCMax, parsedValue)
          }
          if (fetchedData.Minimum_Charge) {
            const parsedValue = parseIntOrFloat(fetchedData.Minimum_Charge) // parsing to cover for directus shift from float to decimal type.
            if (parsedValue) commit(MutationTypes.setSOCMin, parsedValue)
          }
          if (fetchedData.Network_Selection_Override)
            commit(
              MutationTypes.setNetworkSelectionOverride,
              fetchedData.Network_Selection_Override,
            )
          if (fetchedData.Electricity_Cost_Per_Min_Public) {
            const parsedValue = parseIntOrFloat(fetchedData.Electricity_Cost_Per_Min_Public) // parsing to cover for directus shift from float to decimal type.
            if (parsedValue) commit(MutationTypes.setDefaultCostPerMinDC, parsedValue)
          }

          if (fetchedData.Relative_Speed) {
            const parsedValue = parseIntOrFloat(fetchedData.Relative_Speed) // parsing to cover for directus shift from float to decimal type.
            if (parsedValue) commit(MutationTypes.setRelativeSpeed, parsedValue)
          }
          // preferring fleet settings overwrite defaults in setting exists/not null.
          //
          // NOTE: whe fleet data is grabbed this will also overwrite defaults this
          // code block assumes this will be handled separately by that process.
          if (fetchedData.kWh_Cost_Home && !state.fleet?.config?.acCostPerKWh) {
            const parsedValue = parseIntOrFloat(fetchedData.kWh_Cost_Home) // parsing to cover for directus shift from float to decimal type.
            if (parsedValue) commit(MutationTypes.setDefaultHomeCostPerKWh, parsedValue)
          }
          if (fetchedData.kWh_Cost_Public && !state.fleet?.config?.dcCostPerKWh) {
            const parsedValue = parseIntOrFloat(fetchedData.kWh_Cost_Public) // parsing to cover for directus shift from float to decimal type.
            if (parsedValue) commit(MutationTypes.setDefaultPublicCostPerKWh, parsedValue)
          }
          if (fetchedData.Petrol_Cost_Per_Litre && !state.fleet?.config?.petrolCostPerLitre) {
            const parsedValue = parseIntOrFloat(fetchedData.Petrol_Cost_Per_Litre) // parsing to cover for directus shift from float to decimal type.
            if (parsedValue) commit(MutationTypes.setPetrolCostPerLitre, parsedValue)
          }
          if (fetchedData.Petrol_Km_Per_Litre && !state.fleet?.config?.litresPerKm) {
            const parsedValue = parseIntOrFloat(fetchedData.Petrol_Km_Per_Litre) // parsing to cover for directus shift from float to decimal type.
            if (parsedValue) commit(MutationTypes.setPetrolKmPerLitre, parsedValue)
          }
          if (fetchedData.Diesel_Cost_Per_Litre && !state.fleet?.config?.dieselCostPerLitre) {
            const parsedValue = parseIntOrFloat(fetchedData.Diesel_Cost_Per_Litre) // parsing to cover for directus shift from float to decimal type.
            if (parsedValue) commit(MutationTypes.setDieselCostPerLitre, parsedValue)
          }
          if (fetchedData.diesel_road_user_charges) {
            const parsedValue = parseIntOrFloat(fetchedData.diesel_road_user_charges) // parsing to cover for directus shift from float to decimal type.
            if (parsedValue) commit(MutationTypes.setDieselRoadUserCharges, parsedValue)
          }
          if (fetchedData.petrol_road_user_charges) {
            const parsedValue = parseIntOrFloat(fetchedData.petrol_road_user_charges) // parsing to cover for directus shift from float to decimal type.
            if (parsedValue) commit(MutationTypes.setPetrolRoadUserCharges, parsedValue)
          }
          if (fetchedData.electric_road_user_charges) {
            const parsedValue = parseIntOrFloat(fetchedData.electric_road_user_charges) // parsing to cover for directus shift from float to decimal type.
            if (parsedValue) commit(MutationTypes.setElectricRoadUserCharges, parsedValue)
          }
          if (fetchedData.Per_Annum_Maintenance_Current_V) {
            const parsedValue = parseIntOrFloat(fetchedData.Per_Annum_Maintenance_Current_V) // parsing to cover for directus shift from float to decimal type.
            if (parsedValue) commit(MutationTypes.setPerAnnumMaintenanceCurrentV, parsedValue)
          }
          if (fetchedData.Per_Annum_Maintenance_EV) {
            const parsedValue = parseIntOrFloat(fetchedData.Per_Annum_Maintenance_EV) // parsing to cover for directus shift from float to decimal type.
            if (parsedValue) commit(MutationTypes.setPerAnnumMaintenanceEV, parsedValue)
          }
          if (fetchedData.selectedVehicleID) {
            commit(MutationTypes.setSelectedVehicleDirectusID, fetchedData.selectedVehicleID)
            if (state.vehicles.length) {
              const vehicleData = state.vehicles.find(
                (vehicle) => vehicle.directusId === fetchedData.selectedVehicleID,
              )
              if (vehicleData) commit(MutationTypes.setSelectedVehicle, vehicleData.localId)
            }
          }
          if (fetchedData.passengers) {
            const parsedValue = parseIntOrFloat(fetchedData.passengers)
            if (parsedValue) commit(MutationTypes.setPassengers, parsedValue)
          }
          if (fetchedData.Starting_Charge) {
            const parsedValue = parseIntOrFloat(fetchedData.Starting_Charge)
            if (parsedValue) commit(MutationTypes.setSOCAct, parsedValue)
          }
          if (fetchedData.Ending_Charge) {
            const parsedValue = parseIntOrFloat(fetchedData.Ending_Charge)
            if (parsedValue) commit(MutationTypes.setSOCEnd, parsedValue)
          }
          if (fetchedData.Default_Extra_Weight) {
            const parsedValue = parseIntOrFloat(fetchedData.Default_Extra_Weight)
            if (parsedValue) commit(MutationTypes.setExtraWeight, parsedValue)
          }
          commit(MutationTypes.setSettingsTableID, fetchedData.id)
        }
        // update status
        commit(MutationTypes.setSettingsFetchingFlag, false)
      }
    },
    // fetch managed content.
    async [ActionTypes.getManagedContent]({ commit }: { commit: Commit }) {
      const managedContent = await fetchManagedContent()
      if (managedContent && managedContent.length)
        commit(MutationTypes.setManagedContent, managedContent)
    },
    // fetch favourite locations data.
    async [ActionTypes.getFavLocations]({ commit }: { commit: Commit }) {
      const favLocations = await fetchFavouriteLocations()
      if (favLocations && favLocations.length)
        commit(
          MutationTypes.setFavLocations,
          favLocations.map((favLocation) => FavouriteLocation.fromDirectusData(favLocation)),
        )
    },

    // -------------------------------------------------------------------- //
    // ----------------------- data sorting functions --------------------- //
    // -------------------------------------------------------------------- //

    // link fleet vehicles with EV models.
    [ActionTypes.addModelsToFleetVehicles]({
      commit,
      dispatch,
      state,
    }: {
      commit: Commit
      dispatch: Dispatch
      state: State
    }) {
      state.vehicles.forEach((vehicle) => {
        const model = state.evModels.find((model) => model.id === vehicle.eVModelId)
        if (model) {
          vehicle.setEVModel(model)
          commit(MutationTypes.updateIndividualVehicle, vehicle)
        }
        if (state.selectedVehicleDirectusID === vehicle.driverDirectusId) {
          commit(MutationTypes.setSelectedVehicle, vehicle.localId)
        }
      })
      // Chain next step in fetched data sorting.
      dispatch(ActionTypes.addVehiclesToTrips)
      dispatch(ActionTypes.checkSelectedVehicle)
    },

    // fallback to set selected vehicle if one is not selected .
    [ActionTypes.checkSelectedVehicle]({
      commit,
      state,
      getters,
    }: {
      commit: Commit
      state: State
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getters: any
    }) {
      if (state.selectedVehicleDirectusID) {
        if (state.vehicles.length) {
          const vehicleData = state.vehicles.find(
            (vehicle) => vehicle.directusId === state.selectedVehicleDirectusID,
          )
          if (vehicleData) {
            commit(MutationTypes.setSelectedVehicle, vehicleData.localId)
            return
          }
        }
      }
      if (!state.selectedVehicle && state.vehicles.length) {
        if (getters[GettersTypes.customVehicles].length) {
          commit(MutationTypes.setSelectedVehicle, getters[GettersTypes.customVehicles][0].localId)
          return
        }
        if (getters[GettersTypes.connectedVehicles].length) {
          commit(
            MutationTypes.setSelectedVehicle,
            getters[GettersTypes.connectedVehicles][0].localId,
          )
          return
        }
        if (getters[GettersTypes.genericVehicles].length) {
          commit(MutationTypes.setSelectedVehicle, getters[GettersTypes.genericVehicles][0].localId)
          return
        }
      }
    },

    // create generic EV's from models.
    [ActionTypes.createGenericEVsFromModels]({
      commit,
      dispatch,
      state,
    }: {
      commit: Commit
      dispatch: Dispatch
      state: State
    }) {
      const tempArray: Vehicle[] = []
      state.evModels.forEach((model) => {
        const newVehicle = new Vehicle({})
        newVehicle.setEVModel(model)
        newVehicle.name = 'Generic EV'
        newVehicle.vehicleType = VehicleType.GENERIC_VEHICLE
        tempArray.push(newVehicle)
      })
      commit(MutationTypes.setVehicles, [...state.vehicles, ...tempArray])
      dispatch(ActionTypes.checkSelectedVehicle)
    },

    // link trips with fleet vehicles.
    [ActionTypes.addVehiclesToTrips]({ state }: { state: State }) {
      // check both fleet vehicle data and trips data has been fetched.
      if (state.vehicles.length && state.trips.length) {
        state.trips.forEach((trip) => {
          // check if trip has vehicle ID and vehicle data has not yet been added.
          if (trip.vehicleId && !trip.vehicle_data) {
            // apply vehicles data from saved vehicles to trips.
            trip.vehicle = state.vehicles.find((vehicle) => vehicle.directusId === trip.vehicleId)
          } else if (trip.vehicle_EV_model_id && !trip.vehicle_data) {
            // apply vehicles data from generic vehicles to trips.
            trip.vehicle = state.vehicles.find(
              (vehicle) =>
                vehicle.eVModelId === trip.vehicle_EV_model_id && vehicle.name === 'Generic EV',
            )
          }
        })
      }
    },

    // -------------------------------------------------------------------- //
    // ---------------------- data clean up functions --------------------- //
    // -------------------------------------------------------------------- //

    // rest global state to default values.
    [ActionTypes.clearData]({ commit }: { commit: Commit }) {
      commit(MutationTypes.setUser, undefined)
      commit(MutationTypes.setAuthorizedFlag, false)
      commit(MutationTypes.setChargers, [])
      commit(MutationTypes.setDefaultCostPerMinDC, OptimiserDefaultData.electricityCostPerMinPublic)
      commit(MutationTypes.setDefaultPublicCostPerKWh, OptimiserDefaultData.kWhCostPublic)
      commit(MutationTypes.setEvModels, [])
      commit(MutationTypes.setFleet, undefined)
      commit(MutationTypes.setNetworkBranding, [])
      commit(MutationTypes.setRoutePlanningFlag, false)
      commit(MutationTypes.setSOCAct, OptimiserDefaultData.SOCAct)
      commit(MutationTypes.setSOCEnd, OptimiserDefaultData.SOCEnd)
      commit(MutationTypes.setSOCMax, OptimiserDefaultData.SOCMax)
      commit(MutationTypes.setSOCMin, OptimiserDefaultData.SOCMin)
      commit(MutationTypes.setNetworkSelectionOverride, undefined)
      commit(MutationTypes.setDefaultHomeCostPerKWh, OptimiserDefaultData.kWhCostHome)
      commit(MutationTypes.setRelativeSpeed, OptimiserDefaultData.relativeSpeed)
      commit(MutationTypes.setPetrolCostPerLitre, OptimiserDefaultData.petrolCostPerLitre)
      commit(MutationTypes.setPetrolKmPerLitre, OptimiserDefaultData.petrolKmPerLitre)
      commit(MutationTypes.setDieselCostPerLitre, OptimiserDefaultData.dieselCostPerLitre)
      commit(MutationTypes.setDieselKmPerLitre, OptimiserDefaultData.dieselKmPerLitre)
      commit(MutationTypes.setDieselRoadUserCharges, OptimiserDefaultData.roadUserCharges)
      commit(MutationTypes.setPetrolRoadUserCharges, 0)
      commit(MutationTypes.setElectricRoadUserCharges, OptimiserDefaultData.roadUserCharges)
      commit(
        MutationTypes.setPerAnnumMaintenanceCurrentV,
        OptimiserDefaultData.perAnnumMaintenanceCurrentV,
      )
      commit(MutationTypes.setPerAnnumMaintenanceEV, OptimiserDefaultData.perAnnumMaintenanceEV)
      commit(MutationTypes.setCalcVs, CalcVsType.PETROL)
      commit(MutationTypes.setSelectedTrip, undefined)
      commit(MutationTypes.setTrips, [])
      commit(MutationTypes.setDashboardDialogContent, 'home')
      commit(MutationTypes.setAddressSearchHistory, [])
      router.push({
        name: RouteNames.home,
        query: undefined,
      })
    },

    // clear status of target async operation after a delay.
    [ActionTypes.clearStatus](
      { commit, state }: { commit: Commit; state: State },
      mutationType: MutationTypes,
    ) {
      // clear status after a given time.
      setTimeout(() => {
        commit(mutationType, undefined)
        // delayed exceptions
        if (mutationType === MutationTypes.setDeleteTripStatus) {
          commit(MutationTypes.setSecondaryDialogContent, undefined)
          commit(MutationTypes.setQueuedTripForDelete, undefined)
        }
        if (mutationType === MutationTypes.setUpdatingUserStatus) {
          commit(MutationTypes.setMainDialogContent, undefined)
        }
      }, 2000)

      // instant exceptions
      if (mutationType === MutationTypes.setDeleteTripStatus) {
        // check if selected trip needs to be cleared.
        if (state.queuedTripForDelete === state.selectedTrip) {
          commit(MutationTypes.setSelectedTrip, undefined)
        }
      }
    },

    // -------------------------------------------------------------------- //
    // ---------------------- data refresh functions ---------------------- //
    // -------------------------------------------------------------------- //

    // main data refresh call chaining other refresh methods from it.
    [ActionTypes.refreshData]({ dispatch }: { dispatch: Dispatch }) {
      dispatch(ActionTypes.updateVehiclesData)
    },

    // update fleet vehicles data.
    async [ActionTypes.updateVehiclesData]({
      commit,
      dispatch,
      state,
    }: {
      commit: Commit
      dispatch: Dispatch
      state: State
    }) {
      if (state.fleet) {
        const vehiclesData = await fetchFleetVehicles()
        if (vehiclesData) {
          state.vehicles.forEach((vehicle) => {
            const updatedData = vehiclesData.find((data) => data.id === vehicle.directusId)
            if (updatedData) {
              vehicle.updateData(updatedData)
              commit(MutationTypes.updateIndividualVehicle, vehicle)
            }
          })
        }
        // ensure updated vehicle objects are synced with trips vehicle objects.
        dispatch(ActionTypes.addVehiclesToTrips)
      } else {
        dispatch(ActionTypes.getFleetData)
      }
    },

    // -------------------------------------------------------------------- //
    // ---------------------- data update functions ----------------------- //
    // -------------------------------------------------------------------- //

    // update user/driver data
    async [ActionTypes.updateUser](
      {
        commit,
        dispatch,
        state,
        getters,
      }: {
        commit: Commit
        dispatch: Dispatch
        state: State
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getters: any
      },
      payload: DirectusDataToUpdate,
    ) {
      const oldDriverData: Driver | undefined = getters[GettersTypes.currentDriver]

      commit(MutationTypes.setUpdatingUserStatus, AsyncStatus.PROCESSING)

      if (state.user) {
        const res = await updateUser(payload, state.user)
        // update state
        if (res.success && res.updatedDriver) {
          const newDriver = Driver.fromDirectusData(res.updatedDriver)
          if (oldDriverData?.localId) newDriver.localId = oldDriverData.localId
          commit(MutationTypes.updateIndividualDriver, newDriver)
        }
        if (res.success && res.updatedUser) {
          const newUser = User.fromDirectusData(res.updatedUser)
          commit(MutationTypes.setUser, newUser)
        }
        // delete old avatar image
        if (res.success && oldDriverData?.profilePic) {
          await deleteDirectusFile(oldDriverData?.profilePic)
        }

        if (res.success) {
          commit(MutationTypes.setUpdatingUserStatus, AsyncStatus.SUCCESS)
          dispatch(ActionTypes.clearStatus, MutationTypes.setUpdatingUserStatus)
          return
        }
      }

      commit(MutationTypes.setUpdatingUserStatus, AsyncStatus.FAILED)
      dispatch(ActionTypes.clearStatus, MutationTypes.setUpdatingUserStatus)
      return
    },

    // updated selected vehicle and recalculate selected trip if there is
    // one and vehicle is different.
    async [ActionTypes.selectVehicle](
      {
        commit,
        state,
        getters,
      }: {
        commit: Commit
        state: State
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getters: any
      },
      vehicle: Vehicle,
    ) {
      // check if there is a selected trip that may need to be updated.
      if (state.selectedTrip) {
        const selectedTripData: Trip | undefined = getters[GettersTypes.selectedTripData]
        if (
          selectedTripData instanceof Trip &&
          selectedTripData?.vehicleId !== vehicle.directusId
        ) {
          selectedTripData.vehicle = vehicle
          // TODO: something about recalculating the trip
        }
      }

      if (state.selectedVehicle !== vehicle.localId) {
        // update state.
        commit(MutationTypes.setSelectedVehicle, vehicle.localId)
      }

      if (vehicle.directusId && state.selectedVehicleDirectusID !== vehicle.directusId) {
        // update settings
        commit(MutationTypes.setSelectedVehicleDirectusID, vehicle.directusId)
      }
    },

    async [ActionTypes.uploadVehicleImage](
      {
        commit,
        dispatch,
      }: {
        commit: Commit
        dispatch: Dispatch
      },
      { vehicle, imageFile }: UploadVehicleImagePayload,
    ) {
      commit(MutationTypes.setUpdateImageStatus, AsyncStatus.PROCESSING)

      // attempt to upload image to cloud storage and update data.
      const outcome = await vehicle.uploadImage(imageFile)
      if (outcome === 'success') {
        commit(MutationTypes.setUpdateImageStatus, AsyncStatus.SUCCESS)
        commit(MutationTypes.updateIndividualVehicle, vehicle)
        dispatch(ActionTypes.clearStatus, MutationTypes.setUpdateImageStatus)
        return
      }

      // ASSUMES: failed if function reaches this point.
      commit(MutationTypes.setUpdateImageStatus, AsyncStatus.FAILED)
      dispatch(ActionTypes.clearStatus, MutationTypes.setUpdateImageStatus)
      return
    },

    async [ActionTypes.saveVehicle](
      {
        commit,
        dispatch,
        state,
      }: {
        commit: Commit
        dispatch: Dispatch
        state: State
      },
      newVehicle: Vehicle,
    ) {      
      commit(MutationTypes.setVehicleSavingStatus, AsyncStatus.PROCESSING)
      const vehicle = newVehicle
      const saveStatus = await vehicle.saveVehicle()
      if (saveStatus === 'success') {
        if (vehicle.directusId) {
          dispatch(ActionTypes.selectVehicle, vehicle)
        }
        commit(MutationTypes.setVehicleSavingStatus, AsyncStatus.SUCCESS)
      } else {
        commit(MutationTypes.setVehicleSavingStatus, AsyncStatus.FAILED)
      }
      commit(MutationTypes.addIndividualVehicle, vehicle)
      dispatch(ActionTypes.clearStatus, MutationTypes.setVehicleSavingStatus)
    },

    // updates settings that are batch-able in  a single api call
    async [ActionTypes.updatedSettingsData](
      {
        commit,
        dispatch,
        state,
      }: {
        commit: Commit
        dispatch: Dispatch
        state: State
      },
      dataToUpdate: BatchUpdatableSettings,
    ) {
      commit(MutationTypes.setSettingsSavingStatus, 'PROCESSING')
      const dataToSend: Partial<DirectusSettings> = {}

      for (const [key, value] of Object.entries(dataToUpdate)) {
        // check if value has changed.
        if (value === state[key as keyof BatchUpdatableSettings]) continue // no change
        let keyToSend: keyof DirectusSettings | undefined = undefined
        // update local state.
        if (key === 'passengers') {
          commit(MutationTypes.setPassengers, value)
          keyToSend = 'passengers'
        }
        if (key === 'SOCMax') {
          commit(MutationTypes.setSOCMax, value)
          keyToSend = 'Maximum_Charge'
        }
        if (key === 'SOCMin') {
          commit(MutationTypes.setSOCMin, value)
          keyToSend = 'Minimum_Charge'
        }
        if (key === 'SOCAct') {
          commit(MutationTypes.setSOCAct, value)
          keyToSend = 'Starting_Charge'
        }
        if (key === 'SOCEnd') {
          commit(MutationTypes.setSOCEnd, value)
          keyToSend = 'Ending_Charge'
        }
        if (key === 'relativeSpeed') {
          commit(MutationTypes.setRelativeSpeed, value)
          keyToSend = 'Relative_Speed'
        }
        if (key === 'acceleration') commit(MutationTypes.setAcceleration, value)
        if (key === 'extraWeight') {
          commit(MutationTypes.setExtraWeight, value)
          keyToSend = 'Default_Extra_Weight'
        }
        if (key === 'petrolCostPerLitre') {
          commit(MutationTypes.setPetrolCostPerLitre, value)
          keyToSend = 'Petrol_Cost_Per_Litre'
        }
        if (key === 'dieselCostPerLitre') {
          commit(MutationTypes.setDieselCostPerLitre, value)
          keyToSend = 'Diesel_Cost_Per_Litre'
        }
        if (key === 'petrolKmPerLitre') {
          commit(MutationTypes.setPetrolKmPerLitre, value)
          keyToSend = 'Petrol_Km_Per_Litre'
        }
        if (key === 'dieselKmPerLitre') {
          commit(MutationTypes.setDieselKmPerLitre, value)
          keyToSend = 'Diesel_Km_Per_Litre'
        }
        if (key === 'petrolRoadUserCharges') {
          commit(MutationTypes.setPetrolRoadUserCharges, value)
          keyToSend = 'petrol_road_user_charges'
        }
        if (key === 'dieselRoadUserCharges') {
          commit(MutationTypes.setDieselRoadUserCharges, value)
          keyToSend = 'diesel_road_user_charges'
        }
        if (key === 'defaultHomeCostPerKWh') {
          commit(MutationTypes.setDefaultHomeCostPerKWh, value)
          keyToSend = 'kWh_Cost_Home'
        }
        if (key === 'defaultPublicCostPerKWh') {
          commit(MutationTypes.setDefaultPublicCostPerKWh, value)
          keyToSend = 'kWh_Cost_Public'
        }
        if (key === 'defaultCostPerMinDC') {
          commit(MutationTypes.setDefaultCostPerMinDC, value)
          keyToSend = 'Electricity_Cost_Per_Min_Public'
        }
        if (key === 'electricRoadUserCharges') {
          commit(MutationTypes.setElectricRoadUserCharges, value)
          keyToSend = 'electric_road_user_charges'
        }
        if (key === 'calcVs') {
          commit(MutationTypes.setCalcVs, value)
        }
        if (key === 'perAnnumMaintenanceCurrentV') {
          commit(MutationTypes.setPerAnnumMaintenanceCurrentV, value)
          keyToSend = 'Per_Annum_Maintenance_Current_V'
        }
        if (key === 'perAnnumMaintenanceEV') {
          commit(MutationTypes.setPerAnnumMaintenanceEV, value)
          keyToSend = 'Per_Annum_Maintenance_EV'
        }

        if (keyToSend) {
          dataToSend[keyToSend] = typeof value === 'undefined' ? null : value
        }
      }

      if (Object.keys(dataToSend).length > 0 && state.settingsTableID) {
        const res = await updateSettingsRecord(state.settingsTableID, dataToSend)
        if (res) {
          commit(MutationTypes.setSettingsSavingStatus, 'SUCCESS')
        } else {
          commit(MutationTypes.setSettingsSavingStatus, 'FAILED')
        }
        dispatch(ActionTypes.clearStatus, MutationTypes.setSettingsSavingStatus)
      }
    },

    // -------------------------------------------------------------------- //
    // ----------------------- Trip Specific Actions ---------------------- //
    // -------------------------------------------------------------------- //

    async [ActionTypes.showTrip]({ dispatch }: { dispatch: Dispatch }, trip: Trip) {
      dispatch(ActionTypes.showPlannedTrip, trip)
    },

    // Plan and display the passed TripV2 class object.
    async [ActionTypes.showPlannedTrip](
      { commit, dispatch, state }: { commit: Commit; dispatch: Dispatch; state: State },
      trip: Trip,
    ) {
      // update state.
      commit(MutationTypes.setRoutePlanningStatus, AsyncStatus.PROCESSING)
      commit(MutationTypes.setSelectedTrip, trip.localId)
      // check if rout needs to be changed
      if (
        router.currentRoute.value.name !== RouteNames.tripItinerary &&
        router.currentRoute.value.name !== RouteNames.tripStats
      )
        router.push({
          name: RouteNames.tripItinerary,
          query: router.currentRoute.value.query,
        })
      // plan trip if not planned.
      if (trip.status === 'unplanned') await trip.planTrip({
        costPerKWhAC: state.defaultHomeCostPerKWh,
        costPerKWhDC: state.defaultPublicCostPerKWh,
        costPerMin: state.defaultCostPerMinDC,
      })
      // if fall back display nearby chargers.
      if (trip.status === 'fallback') {
        commit(MutationTypes.setDisplayAllChargersAlongRoute, true)
      }
      // if failed display error.
      if (trip.status === 'failed') commit(MutationTypes.setRoutePlanningStatus, AsyncStatus.FAILED)
      // if success display itinerary.
      if (trip.status === 'success' || trip.status === 'fallback')
        commit(MutationTypes.setRoutePlanningStatus, AsyncStatus.SUCCESS)
      // update trip data.
      commit(MutationTypes.updateIndividualTrip, trip)
      // set cleanup in progress.
      dispatch(ActionTypes.clearStatus, MutationTypes.setRoutePlanningStatus)
    },

    // name and save individual trip.
    [ActionTypes.nameAndSaveTrip](
      {
        commit,
        dispatch,
        state,
      }: {
        commit: Commit
        dispatch: Dispatch
        state: State
      },
      {
        tripID,
        name,
      }: {
        tripID: string
        name: string
      },
    ) {
      const tripToSave = state.trips.find((trip) => trip.localId === tripID)
      if (!tripToSave) return
      tripToSave.name = name
      commit(MutationTypes.updateIndividualTrip, tripToSave)
      dispatch(ActionTypes.saveTrip, tripID)
    },

    // save individual trip data
    async [ActionTypes.saveTrip](
      { commit, state, dispatch }: { commit: Commit; state: State; dispatch: Dispatch },
      tripID: string,
    ) {
      commit(MutationTypes.setTripSavingStatus, AsyncStatus.PROCESSING)
      const tripToSave = state.trips.find((trip) => trip.localId === tripID)
      if (!tripToSave) {
        // no trip found update status and exit early.
        commit(MutationTypes.setTripSavingStatus, AsyncStatus.FAILED)
        dispatch(ActionTypes.clearStatus, MutationTypes.setTripSavingStatus)
        return
      }
      const outcome = await tripToSave.saveRoutePlan()
      if (outcome === 'ok') {
        // successful operation update status and exit.
        commit(MutationTypes.setTripSavingStatus, AsyncStatus.SUCCESS)
        commit(MutationTypes.updateIndividualTrip, tripToSave)
        dispatch(ActionTypes.clearStatus, MutationTypes.setTripSavingStatus)
        return
      }

      if (outcome === 'failed') {
        // failed operation update status and exit.
        commit(MutationTypes.setTripSavingStatus, AsyncStatus.FAILED)
        dispatch(ActionTypes.clearStatus, MutationTypes.setTripSavingStatus)
        return
      }
    },

    // delete the trip currently queued for deletion.
    async [ActionTypes.deleteTrip]({
      commit,
      dispatch,
      state,
      getters,
    }: {
      commit: Commit
      dispatch: Dispatch
      state: State
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getters: any
    }) {
      commit(MutationTypes.setDeleteTripStatus, AsyncStatus.PROCESSING)
      const tripToDelete: Trip | undefined = getters[GettersTypes.queuedForDeleteTripData]

      // Trip not found guard clause.
      if (!tripToDelete) {
        commit(MutationTypes.setDeleteTripStatus, AsyncStatus.FAILED)
        dispatch(ActionTypes.clearStatus, MutationTypes.setDeleteTripStatus)
        return
      }

      // Remove trip locally.
      commit(
        MutationTypes.setTrips,
        state.trips.filter((trip) => trip.localId !== tripToDelete.localId),
      )

      // Check if trip has been saved remotely.
      if (tripToDelete instanceof Trip) {
        if (tripToDelete.external_id) {
          const deleteOutcome = await deleteSavedRoutePlans(tripToDelete.external_id)
          // set status outcome.
          if (deleteOutcome === 'ok') commit(MutationTypes.setDeleteTripStatus, AsyncStatus.SUCCESS)
          if (deleteOutcome === 'failed')
            commit(MutationTypes.setDeleteTripStatus, AsyncStatus.FAILED)
          // exit function.
          dispatch(ActionTypes.clearStatus, MutationTypes.setDeleteTripStatus)
          return
        }
      }

      // return outcome of local only deletion.
      // ASSUMES if not exited in above block this was not a trip that was saved remotely.
      commit(MutationTypes.setDeleteTripStatus, AsyncStatus.SUCCESS)
      // clear status after a given period.
      dispatch(ActionTypes.clearStatus, MutationTypes.setDeleteTripStatus)
      return
    },

    // get the longest leap leg data for the currently selected trip.
    async [ActionTypes.showAllChargersOnRoute]({
      commit,
      getters,
    }: {
      commit: Commit
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getters: any
    }) {
      commit(MutationTypes.setRadarFetchingFlag, true)
      const trip: Trip | undefined = getters[GettersTypes.selectedTripData]
      if (!trip) return
      const outcome = await trip.getRadarData()
      if (outcome === 'SUCCESS') {
        commit(MutationTypes.updateIndividualTrip, trip)
        commit(MutationTypes.setDisplayAllChargersAlongRoute, true)
      }
      commit(MutationTypes.setRadarFetchingFlag, false)
    },

    // -------------------------------------------------------------------- //
    // -------------------------- auth functions -------------------------- //
    // -------------------------------------------------------------------- //

    // logout and clean up call.
    [ActionTypes.logoutUser]({ dispatch, commit }: { dispatch: Dispatch; commit: Commit }) {
      commit(MutationTypes.setShowLogin, true)
      logout()
      dispatch(ActionTypes.clearData)
    },
  },
  getters: {
    // get the currently selected trip object.
    [GettersTypes.selectedTripData](state: State): Trip | undefined {
      if (!state.selectedTrip) return // guard clause to prevent returning any objects that have somehow lost their local id.
      return state.trips.find((trip) => trip.localId === state.selectedTrip)
    },
    // get the currently selected vehicle object.
    [GettersTypes.selectedVehicleData](state: State): Vehicle | undefined {
      return state.vehicles.find((vehicle) => vehicle.localId === state.selectedVehicle)
    },
    // get all connected telematics vehicles.
    [GettersTypes.connectedVehicles](state: State): Vehicle[] {
      return state.vehicles.filter(
        (vehicle) => vehicle.vehicleType === VehicleType.TELEMATICS_VEHICLE,
      )
    },
    // get all generic vehicles.
    [GettersTypes.genericVehicles](state: State): Vehicle[] {
      return state.vehicles.filter((vehicle) => vehicle.vehicleType === VehicleType.GENERIC_VEHICLE)
    },
    // get all custom vehicles.
    [GettersTypes.customVehicles](state: State): Vehicle[] {
      return state.vehicles.filter((vehicle) => vehicle.vehicleType === VehicleType.CUSTOM_VEHICLE)
    },
    // get the currently selected charger object.
    [GettersTypes.selectedChargerData](state: State): Charger | undefined {
      return state.chargers.find((charger) => charger.id === state.selectedCharger)
    },
    // get the network branding for the currently selected charger.
    [GettersTypes.selectedChargerBranding](state: State): NetworkBranding | undefined {
      const network = state.chargers.find((charger) => charger.id === state.selectedCharger)
        ?.operator?.name
      if (!network) return
      return state.networkBranding.find((branding) => branding.isThisNetwork(network))
    },
    // get a list of filtered charges for display.
    [GettersTypes.filterChargingStations](state: State, getters): Charger[] {
      const trip: Trip | undefined = getters[GettersTypes.selectedTripData]

      if (trip && trip instanceof Trip && state.displayAllChargersAlongRoute) {
        // Show all chargers along route
        const tempArray: Charger[] = []
        trip.chargersAlongRouteCDBIDs.forEach((CDBID) => {
          const charger = state.chargers.find((charger) => charger.id === CDBID)
          if (charger) tempArray.push(charger)
        })
        return tempArray
      }
      if (trip && trip instanceof Trip && trip.displayedComparisonData) return []
      if (trip && trip instanceof Trip) {
        // Show all chargers along route
        const tempArray: Charger[] = []
        trip.chargingStopCDBIDs.forEach((CDBID) => {
          const charger = state.chargers.find((charger) => charger.id === CDBID)
          if (charger) tempArray.push(charger)
        })
        return tempArray
      }

      let returnArray: Charger[] = state.chargers

      // check if network selection override is being used
      if (state.networkSelectionOverride && state.networkSelectionOverride.length) {
        // filter out chargers that don't match network selection
        const tempArray: Charger[] = []
        returnArray.forEach((charger) => {
          if (
            charger.operator?.name &&
            state.networkSelectionOverride?.includes(charger.operator.name)
          )
            tempArray.push(charger)
        })
        // Update return array.
        returnArray = tempArray
      }

      // check if there is search criteria
      if (state.chargersSearchCriteria) {
        // lowercase searchCriteria
        const lowercaseSearchCriteria = state.chargersSearchCriteria.toLowerCase()
        // filter out chargers that don't match search criteria
        const tempArray: Charger[] = []
        returnArray.forEach((charger) => {
          if (
            charger.name?.toLowerCase().includes(lowercaseSearchCriteria) ||
            charger.addressString.toLowerCase().includes(lowercaseSearchCriteria)
          )
            tempArray.push(charger)
        })
        // Update return array.
        returnArray = tempArray
      }

      // gather filters
      const filters: ChargerFilters[] = []

      // check if private AC chargers are not meant to be showing and need to be filtered.
      if (!state.showPrivateACChargers) filters.push(ChargerFilters.NO_PRIVATE_AC)
      // check if private DC chargers are not meant to be showing and need to be filtered.
      if (!state.showPrivateDCChargers) filters.push(ChargerFilters.NO_PRIVATE_DC)
      // check if public AC chargers are not meant to be showing and need to be filtered.
      if (!state.showPublicACChargers) filters.push(ChargerFilters.NO_PUBLIC_AC)
      // check if public DC chargers are not meant to be showing and need to be filtered.
      if (!state.showPublicDCChargers) filters.push(ChargerFilters.NO_PUBLIC_DC)

      if (filters.length) {
        // filter out chargers that don't match search criteria
        const tempArray: Charger[] = []
        returnArray.forEach((charger) => {
          if (!charger.excludeByFilters(filters)) tempArray.push(charger)
        })
        // Update return array.
        returnArray = tempArray
      }

      // Return filtered array.
      return returnArray
    },
    // get the current users fleet driver data.
    [GettersTypes.currentDriver](state: State): Driver | undefined {
      return state.driver
    },
    // get the current trips selected trip location object.
    [GettersTypes.selectedLocationData](state: State, getters): TripLocation | undefined {
      const trip: Trip | undefined = getters[GettersTypes.selectedTripData]
      if (!trip) return undefined
      return (trip as Trip).locations.find(
        (location) => location.local_id === state.selectedLocation,
      )
    },
    // get the trip data for the trip that is currently queued for deletion.
    [GettersTypes.queuedForDeleteTripData](state: State): Trip | undefined {
      return state.trips.find((trip) => trip.localId === state.queuedTripForDelete)
    },
    // get stats for the currently selected trip
    [GettersTypes.selectedTripStats](state: State, getters): TripStats | undefined {
      const trip: Trip | undefined = getters[GettersTypes.selectedTripData]
      if (!trip) return undefined
      let roadUserChargers: number | undefined
      if (trip.vehicle_data?.fuelType === 'Diesel') {
        roadUserChargers = state.dieselRoadUserCharges
      }
      if (
        trip.vehicle_data?.fuelType === 'Petrol' ||
        trip.vehicle_data?.fuelType === 'Hybrid' ||
        trip.vehicle_data?.fuelType === 'Plug in hybrid'
      ) {
        roadUserChargers = state.petrolRoadUserCharges
      }
      if (trip.vehicle_data?.fuelType === 'Electric') {
        roadUserChargers = state.electricRoadUserCharges
      }

      return trip.getTripStats({
        calcVs: state.calcVs,
        petrolCostPerLitre: state.petrolCostPerLitre,
        petrolKmPerLitre: state.petrolKmPerLitre,
        dieselCostPerLitre: state.dieselCostPerLitre,
        dieselKmPerLitre: state.dieselKmPerLitre,
        kWhCostHome: state.defaultHomeCostPerKWh,
        roadUserCharges: roadUserChargers,
      })
    },
    // get 5yr predictions on savings
    [GettersTypes.getOneYearProjections](state: State): YearsSavings {
      const filteredTrips: Trip[] = []
      if (state.tripsGroups && state.tripsGroups.length) {
        state.tripsGroups.forEach((group) => {
          filteredTrips.push(...group.trips)
        })
      } else {
        filteredTrips.push(...state.trips)
      }

      return calcYearsSavings(filteredTrips, {
        calcVs: state.calcVs,
        dieselCostPerLitre: state.dieselCostPerLitre,
        dieselKmPerLitre: state.dieselKmPerLitre,
        kWhCostHome: state.defaultHomeCostPerKWh,
        kWhCostPublic: state.defaultPublicCostPerKWh,
        perAnnumMaintenanceCurrentV: state.perAnnumMaintenanceCurrentV,
        perAnnumMaintenanceEV: state.perAnnumMaintenanceEV,
        petrolCostPerLitre: state.petrolCostPerLitre,
        petrolKmPerLitre: state.petrolKmPerLitre,
        dieselRoadUserCharges: state.dieselRoadUserCharges,
        petrolRoadUserCharges: state.petrolRoadUserCharges,
        electricRoadUserCharges: state.electricRoadUserCharges,
      })
    },
    // get the currently viewed fleet vehicles data if there is one.
    [GettersTypes.viewedFleetVehicleData](state: State): Vehicle | undefined {
      return state.vehicles.find((vehicle) => vehicle.localId === state.viewedFleetVehicle)
    },
    // get the current vehicle out of viewed fleet vehicle or selected vehicle.
    [GettersTypes.displayedVehicle](state: State, getters): Vehicle | undefined {
      if (state.viewedFleetVehicle) return getters[GettersTypes.viewedFleetVehicleData]
      return getters[GettersTypes.selectedVehicleData]
    },
    // get a filtered list of trips that have been saved
    [GettersTypes.savedTrips](state: State): Trip[] {
      return state.trips.filter((trip) => !!trip.directusId).reverse()
    },
    // get a filtered list of trips that have not been saved
    [GettersTypes.unsavedTrips](state: State): Trip[] {
      return state.trips.filter((trip) => !trip.directusId).reverse()
    },
    [GettersTypes.fetching](state: State): boolean {
      return state.userFetchingFlag || state.chargerFetchingFlag || state.fleetFetchingFlag
    },
    [GettersTypes.getVehicleById](state: State): (id: string) => Vehicle | undefined {
      return (id) => state.vehicles.find((vehicle) => vehicle.localId === id)
    },
  },
  modules: {},
})

export default store
