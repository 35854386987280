import Countly from 'countly-sdk-web'
import Keycloak, { type KeycloakOnLoad } from 'keycloak-js'
import { createApp } from 'vue'
import VueCountly from 'vue-countly'
import 'vuetify/dist/vuetify.min.css'
import packageJson from '../package.json'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store/store'

export let app: any
export let keycloak: Keycloak

class PowertripOptimiser extends HTMLElement {
  connectedCallback() {
    app = createApp(App, {
      created() {
        console.log('Analytics is running:', this.$Countly === Countly)
        ;(this.$Countly as typeof Countly).q.push(['track_sessions']) // Track sessions automatically (recommended)
        ;(this.$Countly as typeof Countly).q.push(['track_pageview']) // Track web page views automatically (recommended)
        ;(this.$Countly as typeof Countly).q.push(['track_errors']) // report unhandled errors to analytics.
      },
    })

    app.use(router)
    app.use(store)
    app.use(vuetify)

    // Analytics.
    app.use(VueCountly, Countly, {
      app_key: '72ab10f127702075ed811caf5d14fc06b4049015',
      url: 'https://analytics.littlemonkey.co.nz',
      app_version: packageJson.version,
    })

    app.provide('Countly', Countly)

    // Auth
    const initOptions = {
      url: 'https://auth.powertrip.earth/auth',
      realm:
        document?.querySelector('powertrip-optimiser')?.attributes?.['realm' as any]?.nodeValue ??
        'powertrip',
      clientId: 'optimiser',
      onLoad: 'login-required',
    }

    keycloak = new Keycloak(initOptions)

    const redirectUri =
      window.location.origin +
      '/#/login/' +
      new URLSearchParams(
        'location=' +
          (window.location.hash.includes('location=')
            ? window.location.hash.replace(/&location=.*/, '')
            : window.location.hash) +
          '&' +
          window.location.search,
      ).toString()

    keycloak
      .init({
        onLoad: initOptions.onLoad as KeycloakOnLoad | undefined,
        checkLoginIframe: false,
        redirectUri: redirectUri,
      })
      .then((auth) => {
        if (!auth) {
          //reload login page
          window.location.reload()
        } else {
          app.mount(this)
        }

        //Token Refresh
        setInterval(() => {
          keycloak
            .updateToken(70)
            .then((refreshed) => {
              if (refreshed) {
                console.log('Token refreshed' + refreshed)
              } else {
                console.log(
                  'Token not refreshed, valid for ' +
                    (keycloak?.tokenParsed?.exp && keycloak?.timeSkew
                      ? Math.round(
                          keycloak.tokenParsed.exp +
                            keycloak.timeSkew -
                            new Date().getTime() / 1000,
                        )
                      : 'unknown') +
                    ' seconds',
                )
              }
            })
            .catch(() => {
              console.log('Failed to refresh token')
            })
        }, 60000)
      })
      .catch(() => {
        console.log('Authenticated Failed')
      })
  }
}

if (!window.customElements.get('powertrip-optimiser')) {
  window.customElements.define('powertrip-optimiser', PowertripOptimiser)
}
