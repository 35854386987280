// colors
const pwt_azure = "#2EAEFF"
const pwt_alabaster = "#FAFAFA"
const pwt_black = "#161616"
const pwt_azure_80 = "#6DC6FF"
const pwt_azure_60 = "#97D7FF"
const pwt_azure_40 = "#C0E7FF"
const pwt_azure_20 = "#EAF7FF"
const pwt_azure_180 = "#1A4566"
const pwt_azure_160 = "#175780"
const pwt_azure_140 = "#258BCC"
const pwt_azure_120 = "#3EA5E6"
const pwt_palatinate_blue = "#2E46FF"
const pwt_aqua = "#05F0FF"
const pwt_mint = "#6AEDB6"
const pwt_marigold = "#FFC23B"
const pwt_pink = "#FF6581"
const pwt_snow = "#FFFFFF"
const pwt_off_white = "#F3F3F3"
const pwt_platinum = "#E8E8E8"
const pwt_gainsboro = "#DADADA"
const pwt_light_grey = "#AEAEAE"
const pwt_medium_grey = "#6A6A6A"
const pwt_dark_grey = "#3B3D43"
const pwt_charcoal = "#303134"
const pwt_signal_black = "#2B2B2B"
const pwt_red = "#C8102E"
const pwt_yellow = "#F89D1F"
const pwt_green = "#8CE2A8"

// exported color scheme
export const colorScheme = {
  pwt_azure,
  pwt_alabaster,
  pwt_black,
  pwt_azure_80,
  pwt_azure_60,
  pwt_azure_40,
  pwt_azure_20,
  pwt_azure_180,
  pwt_azure_160,
  pwt_azure_140,
  pwt_azure_120,
  pwt_palatinate_blue,
  pwt_aqua,
  pwt_mint,
  pwt_marigold,
  pwt_pink,
  pwt_snow,
  pwt_off_white,
  pwt_platinum,
  pwt_gainsboro,
  pwt_light_grey,
  pwt_medium_grey,
  pwt_dark_grey,
  pwt_charcoal,
  pwt_signal_black,
  pwt_red,
  pwt_yellow,
  pwt_green
}




