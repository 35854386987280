import store from '@/store/store'
import type { EVNavEnergy, EVNavRoutePlan, EVNavStep } from '@/types/ev_nav_types'
import type { SavedRouteItineraryStep } from '@/types/trip_specific_types'
import type { Valhalla_Trip } from '@/types/valhalla_types'
import { calcFastChargingCost, calcSlowChargingCost } from '@/utils/calcChargingCostUtils'
import haversineDistance from '@/utils/haversineDistance'
import { decodePolyline } from '@/utils/polylineUtils'
import { latLngBounds } from 'leaflet'
import type Charger from '../charger_classes/charger'
import Vehicle from '../vehicle_classes/vehicle'
import TripLocation from './tripLocation'

interface ItineraryOptions {
  steps?: ItineraryStep[]
  destination?: ItineraryDestination
}

export interface ItineraryStep {
  addressStr: string
  name: string
  polyline: string
  energyBeforeTravelling: number
  energyUsedTravelling: number
  energyAfterTravelling: number
  chargeBeforeCharging: number
  chargeBeforeTravelling: number
  chargeUsedTravelling: number
  chargeAfterTravelling: number
  energyUsedAtLocation: number
  chargeUsedAtLocation: number
  energyAdded: number
  chargeAdded: number
  chargingTime: number
  chargingCost: number
  drivingDistance: number
  ferryTime: number
  travelTime: number
  locationStayTime: number
  locationCDBID?: string
  userAdded: boolean
  arrivalLoadWeight: number
  departureLoadWeight: number
}

export interface ItineraryDestination {
  addressStr: string
  name: string
  arrivalCharge: number
}

export default class Itinerary {
  steps: ItineraryStep[]
  destination?: ItineraryDestination
  constructor(options: ItineraryOptions | undefined = undefined) {
    // check passed steps are formatted correctly
    if (options?.steps) {
      if (!Array.isArray(options.steps)) {
        throw new Error('Steps must be an array of ItineraryStep objects')
      }
      if (options.steps.some((step) => typeof step !== 'object')) {
        throw new Error('Steps must be an array of ItineraryStep objects')
      }
      if (options.steps.some((step) => step.polyline !== '' && !step.polyline)) {
        throw new Error('Each step must have a polyline')
      }
      if (options.steps.some((step) => typeof step.energyBeforeTravelling !== 'number')) {
        throw new Error('Each step must have an energyBeforeTravelling')
      }
      if (options.steps.some((step) => typeof step.energyUsedTravelling !== 'number')) {
        throw new Error('Each step must have an energyUsedTravelling')
      }
      if (options.steps.some((step) => typeof step.energyAfterTravelling !== 'number')) {
        throw new Error('Each step must have an energyAfterTravelling')
      }
    }

    // check passed destination is formatted correctly
    if (options?.destination) {
      if (typeof options.destination !== 'object') {
        throw new Error('Destination must be an ItineraryDestination object')
      }
      if (typeof options.destination.arrivalCharge !== 'number') {
        throw new Error('Destination arrival charge must be a number')
      }
      if (typeof options.destination.name !== 'string') {
        throw new Error('Destination name must be a string')
      }
      if (typeof options.destination.addressStr !== 'string') {
        throw new Error('Destination address string must be a string')
      }
    }

    // set state
    this.steps = options?.steps ?? []
    this.destination = options?.destination
  }

  /**
   * Builds an ItineraryV2 object from a Valhalla trip response, array of locations, array of energy data, starting state of charge, round trip flag, and starting load.
   *
   * @param batterySize The total battery size of the vehicle in kWh.
   * @param trip The Valhalla trip response.
   * @param locations The array of locations in the trip.
   * @param energyData The array of energy data for each step of the trip.
   * @param startingSoC The starting state of charge of the vehicle as a decimal from 0 to 1.
   * @param roundTripFlag Whether the trip is a round trip or not.
   * @param startingLoad The starting load of the vehicle in kg.
   * @returns An ItineraryV2 object built from the given parameters.
   */
  static buildFromValhallaTrip({
    batterySize,
    trip,
    locations,
    energyData,
    startingSoC,
    roundTripFlag,
    startingLoad,
  }: {
    batterySize: number
    trip: Valhalla_Trip
    locations: TripLocation[]
    energyData: EVNavEnergy[] | undefined
    startingSoC: number
    roundTripFlag: boolean
    startingLoad: number
  }): Itinerary {
    const steps: ItineraryStep[] = []
    const destination: ItineraryDestination = {
      addressStr: roundTripFlag ? locations[0].address : locations[locations.length - 1].address,
      name:
        (roundTripFlag ? locations[0].name : locations[locations.length - 1].name) ??
        'unnamed location',
      arrivalCharge: 0,
    }
    let remainingEnergy = batterySize * startingSoC
    let remainingLoad = startingLoad

    // step through legs to create steps
    trip.legs.forEach((leg, index) => {
      // get corresponding starting locations original index
      const originalLocationIndex = trip.locations[index].original_index
      // get corresponding location
      const location =
        originalLocationIndex >= 0
          ? originalLocationIndex > locations.length - 1
            ? roundTripFlag
              ? locations[0]
              : undefined
            : locations[originalLocationIndex]
          : undefined
      if (!location) throw new Error(`starting location relating to leg ${index} not found`)
      // get corresponding energy data
      const legEnergyData = energyData ? energyData[index] : undefined
      // calculate remaining load
      const departureLoadWeight = Math.max(remainingLoad + (location.weightChange ?? 0), 0)
      // create step
      steps.push({
        addressStr: location.address,
        name: location.name ?? 'unnamed location',
        polyline: leg.shape,
        energyBeforeTravelling:
          remainingEnergy +
          (location.stateOfChargeAfterCharging
            ? batterySize * (location.stateOfChargeAfterCharging - remainingEnergy / batterySize)
            : 0) -
          (location.nonDrivingEnergyUsed ?? 0),
        energyUsedTravelling: legEnergyData?.Energy ?? 0,
        energyAfterTravelling:
          remainingEnergy +
          (location.stateOfChargeAfterCharging
            ? batterySize * (location.stateOfChargeAfterCharging - remainingEnergy / batterySize)
            : 0) -
          (location.nonDrivingEnergyUsed ?? 0) -
          (legEnergyData?.Energy ?? 0),
        chargeBeforeCharging: remainingEnergy / batterySize,
        chargeBeforeTravelling:
          (remainingEnergy +
            (location.stateOfChargeAfterCharging
              ? batterySize * (location.stateOfChargeAfterCharging - remainingEnergy / batterySize)
              : 0) -
            (location.nonDrivingEnergyUsed ?? 0)) /
          batterySize,
        chargeUsedTravelling: legEnergyData?.Energy ? legEnergyData.Energy / batterySize : 0,
        chargeAfterTravelling:
          (remainingEnergy +
            (location.stateOfChargeAfterCharging
              ? batterySize * (location.stateOfChargeAfterCharging - remainingEnergy / batterySize)
              : 0) -
            (location.nonDrivingEnergyUsed ?? 0) -
            (legEnergyData?.Energy ?? 0)) /
          batterySize,
        energyUsedAtLocation: location.nonDrivingEnergyUsed ?? 0,
        chargeUsedAtLocation: location.nonDrivingEnergyUsed
          ? location.nonDrivingEnergyUsed / batterySize
          : 0,
        energyAdded: location.stateOfChargeAfterCharging
          ? batterySize * (location.stateOfChargeAfterCharging - remainingEnergy / batterySize)
          : 0,
        chargeAdded: location.stateOfChargeAfterCharging
          ? location.stateOfChargeAfterCharging - remainingEnergy / batterySize
          : 0,
        chargingTime: 0,
        chargingCost: 0,
        drivingDistance: leg.summary.length * 1000,
        ferryTime: leg.summary.has_ferry
          ? leg.maneuvers.reduce(
              (acc, manoeuvre) => acc + (manoeuvre.ferry ? manoeuvre.time : 0),
              0,
            )
          : 0,
        travelTime: leg.summary.has_ferry
          ? leg.maneuvers.reduce(
              (acc, manoeuvre) => acc + (!manoeuvre.ferry ? manoeuvre.time : 0),
              0,
            )
          : leg.summary.time,
        locationCDBID: undefined,
        locationStayTime: location.stay ?? 0,
        userAdded: !!location,
        arrivalLoadWeight: remainingLoad,
        departureLoadWeight,
      })
      // update remaining energy
      remainingEnergy =
        remainingEnergy -
        (legEnergyData?.Energy ?? 0) -
        (location.nonDrivingEnergyUsed ?? 0) +
        (location.stateOfChargeAfterCharging
          ? location.stateOfChargeAfterCharging - remainingEnergy / batterySize
          : 0)
      // update remaining load
      remainingLoad = departureLoadWeight
    })

    // calculate arrival charge
    destination.arrivalCharge = remainingEnergy / batterySize

    // generate and return itinerary
    return new Itinerary({
      steps,
      destination,
    })
  }

  /**
   * Builds an ItineraryV2 object from an EVNav trip response, array of locations, round trip flag, and starting load.
   *
   * @param batterySize The total battery size of the vehicle in kWh.
   * @param trip The EVNav trip response.
   * @param locations The array of locations in the trip.
   * @param roundTripFlag Whether the trip is a round trip or not.
   * @param startingLoad The starting load of the vehicle in kg.
   * @returns An ItineraryV2 object built from the given parameters.
   */
  static buildFromEVNavTrip({
    vehicle,
    trip,
    locations,
    roundTripFlag,
    startingLoad,
    costPerKWhDC,
    costPerKWhAC,
    costPerMin,
  }: {
    vehicle: Vehicle
    trip: EVNavRoutePlan | EVNavRoutePlan[]
    locations: TripLocation[]
    roundTripFlag: boolean
    startingLoad: number
    costPerKWhDC: number
    costPerKWhAC: number
    costPerMin: number
  }): Itinerary {
    const flatEVNavSteps: EVNavStep[] =
      trip instanceof Array ? trip.flatMap((plan) => plan.Steps) : trip.Steps
    const destination: ItineraryDestination = {
      addressStr: roundTripFlag ? locations[0].address : locations[locations.length - 1].address,
      name:
        (roundTripFlag ? locations[0].name : locations[locations.length - 1].name) ??
        'unnamed location',
      arrivalCharge: flatEVNavSteps[flatEVNavSteps.length - 1].EndCharge,
    }
    let remainingLoad = startingLoad
    const steps: ItineraryStep[] = []
    flatEVNavSteps.forEach((step) => {
      const bestConnector = step.Charger?.Ports
        ? vehicle.selectBestConnector(step.Charger.Ports)
        : null
      // get corresponding starting location
      const location = locations.find((location) => location.local_id === step.From)
      // calculate remaining load
      const departureLoadWeight = Math.max(remainingLoad + (location?.weightChange ?? 0), 0)
      // create step
      steps.push({
        addressStr: location?.address ?? step.Charger?.ParkName ?? 'Unknown address',
        name:
          location?.name ??
          step.Charger?.ParkName ??
          (step.Charger?.Network ? step.Charger.Network + ' charger' : 'unknown network charger'),
        polyline: step.Polyline,
        energyBeforeTravelling: vehicle.totalBatteryKWh() * step.StartCharge,
        energyUsedTravelling: step.Energy,
        energyAfterTravelling: vehicle.totalBatteryKWh() * step.EndCharge,
        chargeBeforeCharging: step.ArrivalCharge,
        chargeBeforeTravelling: step.StartCharge,
        chargeUsedTravelling: step.Energy / vehicle.totalBatteryKWh(),
        chargeAfterTravelling: step.EndCharge,
        energyUsedAtLocation: location?.nonDrivingEnergyUsed ?? 0,
        chargeUsedAtLocation: location?.nonDrivingEnergyUsed
          ? vehicle.totalBatteryKWh() * location.nonDrivingEnergyUsed
          : 0,
        energyAdded: step.Charge ?? 0,
        chargeAdded: step.Charge ? step.Charge / vehicle.totalBatteryKWh() : 0,
        chargingTime: step.ChargeTime ?? 0,
        chargingCost: bestConnector
          ? bestConnector.PowerType === 'DC'
            ? calcFastChargingCost({
                energyCharged: step.Charge ?? 0,
                timeCharged: (step.ChargeTime ?? 0) / 60,
                costPerKWH: costPerKWhDC,
                costPerMin,
              })
            : calcSlowChargingCost({
                energyCharged: step.Charge ?? 0,
                costPerKWH: costPerKWhAC,
              })
          : 0,
        drivingDistance: step.Distance,
        ferryTime: step.FerryTime,
        travelTime: step.TravelTime - (step.ChargeTime ?? 0),
        locationCDBID: step.Charger?.CDBID ?? undefined,
        locationStayTime: location?.stay ?? 0,
        userAdded: !!location,
        arrivalLoadWeight: remainingLoad,
        departureLoadWeight,
      })
      // update remaining load
      remainingLoad = departureLoadWeight
    })

    // generate and return itinerary
    return new Itinerary({
      steps,
      destination,
    })
  }

  /**
   * Builds an ItineraryV2 object from a saved data object with optional properties.
   *
   * This function takes a saved data object with optional properties and returns a
   * fully formed ItineraryV2 object. All properties that are not provided will be
   * filled in with default values from a blank ItineraryV2Step or ItineraryV2Destination.
   *
   * @param {Object} data - Saved data object with optional properties.
   * @returns {Itinerary} - Fully formed ItineraryV2 object.
   */
  static buildFromSavedData({
    steps,
    destination,
  }: {
    steps: Partial<SavedRouteItineraryStep>[]
    destination: Partial<ItineraryDestination>
  }) {
    const formattedSteps: ItineraryStep[] = steps.map((step) => {
      return {
        addressStr: step.addressStr ?? '',
        name: step.name ?? '',
        polyline: step.polyline ?? '',
        energyBeforeTravelling: step.energyBeforeTraveling ?? 0,
        energyUsedTravelling: step.energyUsedTraveling ?? 0,
        energyAfterTravelling: step.energyAfterTraveling ?? 0,
        chargeBeforeCharging: step.chargeBeforeCharging ?? 0,
        chargeBeforeTravelling: step.chargeBeforeTraveling ?? 0,
        chargeUsedTravelling: step.chargeUsedTraveling ?? 0,
        chargeAfterTravelling: step.chargeAfterTraveling ?? 0,
        energyUsedAtLocation: step.energyUsedAtLocation ?? 0,
        chargeUsedAtLocation: step.chargeUsedAtLocation ?? 0,
        energyAdded: step.energyAdded ?? 0,
        chargeAdded: step.chargeAdded ?? 0,
        chargingTime: step.chargingTime ?? 0,
        chargingCost: step.chargingCost ?? 0,
        drivingDistance: step.drivingDistance ?? 0,
        ferryTime: step.ferryTime ?? 0,
        travelTime: step.travelTime ?? 0,
        locationCDBID: step.locationCDBID ?? undefined,
        locationStayTime: step.locationStayTime ?? 0,
        userAdded: step.userAdded ?? false,
        arrivalLoadWeight: step.arrivalLoadWeight ?? 0,
        departureLoadWeight: step.departureLoadWeight ?? 0,
      }
    })
    const formattedDestination: ItineraryDestination = {
      addressStr: '',
      name: '',
      arrivalCharge: 0,
      ...destination,
    }

    return new Itinerary({
      steps: formattedSteps,
      destination: formattedDestination,
    })
  }

  // ----------------------------------------------------------------------- //
  // -------------------------------- Getters ------------------------------ //
  // ----------------------------------------------------------------------- //

  /**
   * Checks if the ItineraryV2 instance is valid.
   *
   * @return {boolean} Returns true if the instance has at least one step and a
   * destination, otherwise false.
   */
  public get isValid(): boolean {
    return !!this.steps.length && !!this.destination
  }

  /**
   * Calculate the total travel time spent driving for the trip.
   *
   * @return {number} The total driving time in seconds.
   */
  public get totalTravelTime(): number {
    return this.steps.reduce((acc, step) => acc + step.travelTime, 0)
  }

  /**
   * Returns the total time spent at scheduled stops and charging stops for all
   * steps in the itinerary.
   *
   * @return {number} The total stopped time in seconds.
   */
  public get totalStoppedTime(): number {
    return this.steps.reduce(
      (acc, step) => acc + Math.max(step.locationStayTime, step.chargingTime),
      0,
    )
  }

  /**
   * Calculate the total ferry time for the trip.
   *
   * @return {number} The total ferry time in seconds.
   */
  public get totalFerryTime(): number {
    return this.steps.reduce((acc, step) => acc + step.ferryTime, 0)
  }

  /**
   * Returns the total time for the trip in seconds.
   *
   * @return {number} The total time for the trip in seconds.
   */
  public get totalTime(): number {
    return this.totalTravelTime + this.totalStoppedTime + this.totalFerryTime
  }

  /**
   * Calculates the total driving distance for the trip by summing up the
   * driving distances of each step in meters.
   *
   * @return {number} The total driving distance for the trip in meters.
   */
  public get totalDrivingDistance(): number {
    return this.steps.reduce((acc, step) => acc + step.drivingDistance, 0)
  }

  /**
   * Returns the points of each leg in the trip as latitude and longitude
   * coordinate arrays.
   *
   * @return {[number, number][][]} The points of each leg in the trip.
   */
  public get pointsByLeg(): [number, number][][] {
    return this.steps.map((step) => decodePolyline(step.polyline))
  }

  /**
   * Returns an array of charger IDs for chargers stopped at as part of the trip.
   *
   * @return {string[]} An array of charger IDs.
   */
  public get chargerIDs(): string[] {
    return this.steps
      .filter((step) => !!step.locationCDBID)

      .map((step) => step.locationCDBID!)
  }

  /**
   * Calculates the total cost of charging for all steps in the itinerary.
   *
   * @return {number} The total cost of charging.
   */
  public get totalChargingCost(): number {
    return this.steps.reduce((acc, step) => acc + step.chargingCost, 0)
  }

  /**
   * Calculates the total charging time for all steps in the itinerary.
   *
   * @return {number} The total charging time.
   */
  public get totalChargingTime(): number {
    return this.steps.reduce((acc, step) => acc + step.chargingTime, 0)
  }

  /**
   * Calculates the total energy used by all steps in the itinerary.
   *
   * @return {number} The total energy used.
   */
  public get totalEnergyUsed(): number {
    return this.steps.reduce(
      (acc, step) => acc + step.energyUsedAtLocation + step.energyUsedTravelling,
      0,
    )
  }

  /**
   * Calculates the total private energy added in the itinerary.
   *
   * Note: private is defined by charging at a charger that is not recognised by the charger DB.
   *
   * @return {number} The total private energy added.
   */
  public get totalPrivateEnergyAdded(): number {
    return this.steps
      .filter((step) => !step.locationCDBID)
      .reduce((acc, step) => acc + (step.energyAdded ?? 0), 0)
  }

  /**
   * Calculates the total public energy added in the itinerary.
   *
   * Note: public is defined by charging at a charger that is recognised by the charger DB.
   *
   * @return {number} The total public energy added.
   */
  public get totalPublicEnergyAdded(): number {
    return this.steps
      .filter((step) => step.locationCDBID)
      .reduce((acc, step) => acc + (step.energyAdded ?? 0), 0)
  }

  /**
   * Calculates the total energy added in the itinerary.
   *
   * @return {number} The total energy added.
   */
  public get totalEnergyAdded(): number {
    return this.totalPrivateEnergyAdded + this.totalPublicEnergyAdded
  }

  /**
   * Recalculates all floating data like current energy/load etc. in the
   * itinerary replacing values where needed.
   *
   * Note: This should be called after any changes to the itinerary or its
   * data. This also dose not change the first step of the itinerary only the
   * remaining steps. Carrying that data from the first step forward with the
   * remaining steps energy usage, charging times etc.
   *
   * @param vehicle - Vehicle object to calculate energy usage and charging
   * times with. If undefined, will not update energy usage and charging times.
   */
  recalculateFloatingData(vehicle?: Vehicle) {
    const tempStepsArray: ItineraryStep[] = []
    this.steps.forEach((step, index) => {
      if (index === 0) {
        tempStepsArray.push(step)
      } else {
        // charging data
        const chargeBeforeCharging = tempStepsArray[index - 1].chargeAfterTravelling
        const chargeAdded = Math.min(step.chargeAdded ?? 0, 1 - chargeBeforeCharging)
        const chargeBeforeTravelling = Math.min(
          chargeBeforeCharging + chargeAdded - step.chargeUsedAtLocation,
          1,
        )

        const chargeAfterTravelling = chargeBeforeTravelling - step.chargeUsedTravelling
        const energyAdded = vehicle?.totalBatteryKWh()
          ? vehicle.totalBatteryKWh() * chargeAdded
          : step.energyAdded
        const energyBeforeTravelling = vehicle?.totalBatteryKWh()
          ? Math.min(
              tempStepsArray[index - 1].energyAfterTravelling -
                step.energyUsedAtLocation +
                (energyAdded ?? 0),
              vehicle.totalBatteryKWh(),
            )
          : tempStepsArray[index - 1].energyAfterTravelling -
            step.energyUsedAtLocation +
            (energyAdded ?? 0)
        const energyAfterTravelling = energyBeforeTravelling - step.energyUsedTravelling
        // charging time
        const chargers: Charger[] = store.state.chargers
        const charger: Charger | undefined = chargers.find(
          (charger) => charger.id === step.locationCDBID,
        )
        const connector = vehicle ? charger?.bestCompatibleConnector(vehicle) : undefined
        const chargingEstimate = vehicle
          ? connector?.getChargingEstimateDetails(
              vehicle,
              {
                min: chargeBeforeCharging * 100,
                max: chargeBeforeTravelling * 100,
              },
              connector.powerType === 'DC'
                ? store.state.defaultPublicCostPerKWh
                : store.state.defaultHomeCostPerKWh,
              connector.powerType === 'DC' ? store.state.defaultCostPerMinDC : 0,
            )
          : undefined
        const chargingTime = chargingEstimate?.chargingTimeInSeconds ?? 0
        const chargingCost = chargingEstimate?.chargingCost ?? 0

        tempStepsArray.push({
          ...step,
          chargeBeforeCharging,
          chargeBeforeTravelling,
          chargeAfterTravelling,
          chargeAdded,
          energyBeforeTravelling,
          energyAfterTravelling,
          energyAdded,
          chargingTime,
          chargingCost,
        })
      }
    })

    this.steps = tempStepsArray
    if (this.destination)
      this.destination.arrivalCharge = this.steps[this.steps.length - 1].chargeAfterTravelling
  }

  findClosestStepIndex({ lat, lon }: { lat: number; lon: number }): number {
    const stepDistances = this.steps.map((step, index) => {
      const points = decodePolyline(step.polyline)
      const distanceToStartPoint = haversineDistance([lon, lat], [points[0][1], points[0][0]])
      const distanceToEndPoint = haversineDistance(
        [lon, lat],
        [points[points.length - 1][1], points[points.length - 1][0]],
      )
      return {
        distanceScore: distanceToStartPoint + distanceToEndPoint,
        index: index,
      }
    })
    stepDistances.sort((a, b) => a.distanceScore - b.distanceScore)
    return stepDistances[0].index
  }

  /**
   * Orders the steps in the itinerary by the distance from a given point. If
   * the point is within the bounds of a step's polyline, the step is
   * considered to be "in bounds".
   *
   * @param {Object} options
   * @param {number} options.lat
   * @param {number} options.lon
   * @returns {Object[]} array of objects containing the step's index, distance
   * from the point, whether the point is in bounds, and the step's name
   */
  public orderStepsByDistanceFromPoint({ lat, lon }: { lat: number; lon: number }): {
    distanceScore: number
    stepIndex: number
    pointInBounds: boolean
    displayName: string
  }[] {
    const stepDistances = this.steps.map((step, index) => {
      const points = decodePolyline(step.polyline)
      const pointInBounds = latLngBounds(points).contains([lat, lon])
      const distanceToStartPoint = haversineDistance([lon, lat], [points[0][1], points[0][0]])
      const distanceToEndPoint = haversineDistance(
        [lon, lat],
        [points[points.length - 1][1], points[points.length - 1][0]],
      )
      return {
        distanceScore: distanceToStartPoint + distanceToEndPoint,
        stepIndex: index,
        pointInBounds,
        displayName:
          this.steps[index].name +
          ' to ' +
          ((this.steps[index + 1] ? this.steps[index + 1].name : this.destination?.name) ??
            'destination'),
      }
    })
    stepDistances.sort((a, b) => a.distanceScore - b.distanceScore)
    return stepDistances
  }

  /**
   * Returns an array of SavedRouteItineraryStep objects, which are the directus-formatted
   * versions of the steps in the itinerary. The property names are converted from NZ-en to US-en to
   * match the directus schema.
   * @returns {SavedRouteItineraryStep[]}
   */
  public get directusFormattedSteps(): SavedRouteItineraryStep[] {
    return this.steps.map(
      (step): SavedRouteItineraryStep => ({
        ...step,
        chargeAfterTraveling: step.chargeAfterTravelling,
        chargeBeforeTraveling: step.chargeBeforeTravelling,
        energyAfterTraveling: step.energyAfterTravelling,
        energyBeforeTraveling: step.energyBeforeTravelling,
        chargeUsedTraveling: step.chargeUsedTravelling,
        energyUsedTraveling: step.energyUsedTravelling,
      }),
    )
  }
}
