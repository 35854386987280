<template>
  <v-card class="mb-3" @click="showTrip" :loading="fetching" :disabled="fetching">
    <v-row no-gutters align="center">
      <v-card-title v-if="trip.name" class="text-truncate" style="word-break: normal; max-width: 280px">
        <span class="text-truncate">{{ trip.name }}</span>
      </v-card-title>
      <v-card-title v-else class="text-truncate" style="word-break: normal; max-width: 280px ">
        <span class="text-truncate">
          Trip to
          {{
            trip.locations[trip.locations.length - 1].name ??
            trip.locations[trip.locations.length - 1].address
          }}
        </span>
      </v-card-title>
    </v-row>
    <v-card-text>
      <ul style="list-style: none; padding-left: unset">
        <li>{{ niceDuration(trip.itinerary.totalTravelTime) }}</li>
        <li>{{ trip.chargingStopCDBIDs.length }} charging stops</li>
      </ul>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn icon @click.stop="saveTrip">
        <v-progress-circular
          indeterminate
          color="primary"
          size="30"
          v-if="processing && savingStatus === 'PROCESSING'"
        ></v-progress-circular>
        <v-icon color="success" large v-else-if="processing && savingStatus === 'SUCCESS'">
          mdi-check</v-icon
        >
        <v-icon color="error" large v-else-if="processing && savingStatus === 'FAILED'">
          mdi-close</v-icon
        >
        <v-icon color="primary" large v-else> mdi-content-save</v-icon>
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="showDialog" width="300">
      <v-card class="rounded-lg">
        <v-card-title> Name it and save it </v-card-title>
        <v-card-text>
          <v-text-field v-model="inputtedName" label="name" clearable></v-text-field>
        </v-card-text>
        <v-card-actions>
          <ElevatedBtn @click="nameAndSave"> Save </ElevatedBtn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script setup lang="ts">
import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import Trip from '@/classes/trip_classes/trip'
import ElevatedBtn from '@/components/ui-elements/buttons/ElevatedBtn.vue'
import { ActionTypes, GettersTypes } from '@/store/store_types'
import { getNiceDuration } from '@/utils/timeUtils'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps<{ trip: Trip }>()

const showDialog = ref(false)
const inputtedName = ref('')
const processing = ref(false)

const savingStatus = computed(() => store.state.tripSavingStatus)
const fetching = computed(() => store.getters[GettersTypes.fetching])

function niceDuration(seconds: number): string {
  return getNiceDuration(seconds)
}

function showTrip() {
  store.dispatch(ActionTypes.showTrip, props.trip)
}

function saveTrip() {
  processing.value = true
  // check if trip has name
  if (!props.trip.name) {
    showDialog.value = true
    return
  }
  // Notify analytics server
  notifyAnalytics({
    type: 'add_event',
    data: {
      key: 'Trip Saved',
      count: 1,
    },
  })
  store.dispatch(ActionTypes.saveTrip, props.trip.local_id)
}

function nameAndSave() {
  processing.value = true
  if (inputtedName.value) {
    store.dispatch(ActionTypes.nameAndSaveTrip, {
      tripID: props.trip.local_id,
      name: inputtedName.value,
    })
  } else {
    store.dispatch(ActionTypes.saveTrip, props.trip.local_id)
  }
  showDialog.value = false
}

watch(savingStatus, () => {
  if (!savingStatus.value) {
    processing.value = false
  }
})
</script>
